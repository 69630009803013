import { Inject, Injectable } from "@angular/core";
import { Language, UserLanguage } from "src/app/shared/enums/user-language.enum";
import { CustomerService } from "../account-settings/customer/customer.service";
import { LocalStorageService } from "src/app/core/local-storage.service";
import { SegmentIoService } from "../segmentIo/segmentIo.service";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AppTranslateService {
  private _languageChanged = new Subject();
  /** TEMP: for the old property that used acros the components */
  currentLanguage: string;
  currLang: Language;

  get languageChanged(): any {
    return this._languageChanged.asObservable();
  }

  private _languages: Language[] = [
    {
      code: "en",
      type: UserLanguage.English,
      dir: "ltr",
      name: "LANGUAGES.EN_US",
      newFlow: "New Flow",
      newFolder: "New Folder",
      isRTL: false,
    },
    {
      code: "ar",
      type: UserLanguage.Arabic,
      dir: "rtl",
      name: "LANGUAGES.EN_US",
      newFlow: "مجموعة رسائل جديدة",
      newFolder: "حافظة جديدة",
      isRTL: true,
    },
    {
      code: "fr",
      type: UserLanguage.French,
      dir: "ltr",
      name: "LANGUAGES.EN_US",
      newFlow: "New Flow",
      newFolder: "New Folder",
      isRTL: false,
    },
  ];

  private _setCurrLang(lang: Language): void {
    if (!lang) return;
    this.currentLanguage = lang.code;
    this.currLang = lang;
    this.localStorage.setLanguage(lang.type);
    // this.translate.use(lang.code);
    this._handleViewDir(lang);
  }

  private _handleViewDir(lang: Language) {
    this.document.body.dir = lang.dir;
    this.document.documentElement.lang = lang.code;
    this.document.dir = lang.dir;
  }

  private get _authRoutes(): boolean {
    return (
      window.location.href.includes("register") ||
      window.location.href.includes("verify-email") ||
      window.location.href.includes("login") ||
      window.location.href.includes("sent") ||
      window.location.href.includes("forgot-password") ||
      window.location.href.includes("failed") ||
      window.location.href.includes("change-password") ||
      window.location.href.includes("reset-successfully") ||
      window.location.href.includes("reset-password") ||
      window.location.href.includes("new-email") ||
      window.location.href.includes("confirm-newEmail") ||
      window.location.href.includes("verify") ||
      window.location.href.includes("two-factor") ||
      window.location.href.includes("diff-method") ||
      window.location.href.includes("send2FA-email") ||
      window.location.href.includes("success-2FA") ||
      window.location.href.includes("sign-up")
    );
  }

  private get SelectedLanguage(): Language {
    const SelectedLanguage: UserLanguage = +this.localStorage.getLanguage() || UserLanguage.English;
    return this._languages.find((lang) => lang.type === SelectedLanguage);
  }

  constructor(
    private translate: TranslateService,
    private customerService: CustomerService,
    private localStorage: LocalStorageService,
    private segmentIoService: SegmentIoService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._setCurrLang(this.SelectedLanguage);
    // this.translate.onLangChange.subscribe((res) => {
    //   if (res) {
    //     const EncryptedId = this.localStorage.getCurrentCustomerId();
    //     const SelectedLanguage: Language = this._languages.find((lang) => lang.code === res.lang);
    //     if (this._authRoutes) {
    //       this._setCurrLang(SelectedLanguage);
    //       this._languageChanged.next();
    //       return;
    //     }

    //     this.customerService.saveUserLanguage(SelectedLanguage.type, EncryptedId).subscribe((data) => {
    //       if (data) {
    //         this._languageChanged.next();
    //         this._setCurrLang(SelectedLanguage);
    //         this.segmentIoService.handelLanguageChanging(EncryptedId, SelectedLanguage.type);
    //       }
    //     });
    //   }
    // });
  }

  changeLanguage(langCode: string): void {
    this.translate.use(langCode);
    // TODO: this implementation should be moved to constructor, just for old behavior
    const EncryptedId = this.localStorage.getCurrentCustomerId();
    const SelectedLanguage: Language = this._languages.find((lang) => lang.code === langCode);
    if (this._authRoutes) {
      this._setCurrLang(SelectedLanguage);
      this._languageChanged.next();
      return;
    }
    this._setCurrLang(SelectedLanguage);
    this._languageChanged.next();
    this.segmentIoService.handelLanguageChanging(EncryptedId, SelectedLanguage.type);

    this.customerService.saveUserLanguage(SelectedLanguage.type, EncryptedId).subscribe(
      (data) => {},
      () => {
        this._setCurrLang(this._languages.find((lang) => lang.code === "en"));
        this._languageChanged.next();
        this.segmentIoService.handelLanguageChanging(EncryptedId, UserLanguage.English);
      }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { Intercom } from "ng-intercom";
import { AppConfig } from "src/app.config";
import { Router } from "@angular/router";
import { IntercomService } from "./shared/intercom.service";
import { AlertsService, AlertTypes } from "./core/alerts/alerts.service";
import { AuthService } from "./services/auth/auth.service";
import { LocalStorageService } from "./core";
import { AppService } from "./shared/services/app.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AppConfigurations } from "./shared/models/app-config.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  isPartner: boolean;
  isExistConfigs: boolean = false;
  constructor(
    public intercom: Intercom,
    private router: Router,
    private intercomService: IntercomService,
    private alerts: AlertsService,
    private authService: AuthService,
    private appService: AppService,
    private localStorage: LocalStorageService
  ) {
    this.router.events.subscribe((event) => {
      this.intercomService.checkHideIntercom();
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.getConfigurations();
    this.loadFbSDK();
    this.InitSegmentIo();
    this.loadAdBlockerScript();
    this.intercomService.checkPartner();

    this.handleRefreshToken();
    this.handleLastInteraction();
    // this.playSilentSound();
  }

  getConfigurations() {
    this.appService.getConfiguration().subscribe(
      (res: any) => {
        if (res) {
          const metaData = JSON.parse(res.Metadata || "{}");
          if (!metaData.HideIntercome) {
            this.intercomService.initIntercom();
          }
          this.appService.setDomainConfiguration(metaData);
          this.isExistConfigs = true;
        }
      },
      (res: HttpErrorResponse) => {
        this.intercomService.initIntercom();
        this.appService.setDomainConfiguration(this.appService.widebotConfigs);
        this.isExistConfigs = true;
      }
    );
  }

  /**
   * subscribe to refreshToken Subject to send refreshToken request
   * set the new time for the new accessToken
   */
  handleRefreshToken() {
    this.authService.sendRefreshTokenSubject.subscribe((res) => {
      if (res && this.localStorage.getloginTime()) {
        this.authService.refreshAccessToken().subscribe((res) => {
          if (res) {
            this.authService.setTokenTime();
            this.localStorage.setToken(res);
          }
        });
      }
    });
    this.setNewTimeForToken();
  }

  /**
   * if user refresh calculate the token time from the login time saved in localstorage
   */
  setNewTimeForToken() {
    let loginTime = this.localStorage.getloginTime() ? Number(this.localStorage.getloginTime()) : null;
    let currentTime = new Date().getTime();
    if (loginTime) {
      if (currentTime > loginTime) {
        let differenceTime = currentTime - loginTime;
        let expireTime = AppConfig.AcessTokenTime - differenceTime;
        this.authService.TokenTime = setTimeout(() => {
          this.authService.sendRefreshTokenSubject.next(true);
        }, expireTime - 300000); // before 5 min of expiration
      }
    } else {
      clearTimeout(this.authService.TokenTime);
    }
  }

  /**
   * if user didn't interact with the app during token time
   * it shouldn't send refreshToken
   */
  handleLastInteraction() {
    this.authService.sendLastInteractionSubject.subscribe((res) => {
      if (res) {
        this.authService.setLastInteractionTime();
        this.authService.removeRefreshToken();
      }
    });
  }

  loadFbSDK() {
    const headTag = document.getElementsByTagName("head")[0];
    const FBScript = document.createElement("script");
    FBScript.async = true;
    FBScript.defer = true;
    if (AppConfig.isOnPrem === false) {
      FBScript.src = "../assets/js/fb_sdk.js";
    } else {
      FBScript.src = "./assets/js/fb_sdk.js";
      if(AppConfig.hasFbIntegrity === true) {
        FBScript.integrity =
        "sha384-jq270TwE9gNHf2wmpRrlIe7IdUYbYDhCdX/nqvLXwxv/agfJPrDSvR/iTBkmqFGc sha256-qIm1ChpkllurWKLGoIBT/NObVUKXYI9Is9IT3Gtf2ak=";
      }
      FBScript.crossOrigin = "anonymous";
    }

    headTag.appendChild(FBScript);
  }

  loadAdBlockerScript() {
    if (AppConfig.isOnPrem) {
      return;
    }

    const headTag = document.getElementsByTagName("head")[0];
    const checkerScript = document.createElement("script");
    checkerScript.async = true;
    checkerScript.src = "../assets/js/adblocker.js";
    headTag.appendChild(checkerScript);
  }

  InitSegmentIo() {
    if (AppConfig.isOnPrem === true) {
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        window.console && console.error && console.error("Segment snippet included twice.");
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          const t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
          const n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = "4.15.3";
        analytics.load(AppConfig.segmentioId); // Loading segmentIo
        analytics.page();
      }
    }
  }

  /**play sound on first init - to stop browser behavior (waiting user to interact) */
  playSilentSound() {
    let audio = new Audio();
    audio.src = "assets/audio/silence.mp3";
    audio.load();
    audio.play();
  }
}

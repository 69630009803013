import { Injectable } from "@angular/core";
import { SegmentIoBackendService } from "./segmentIo-backend.service";
import { Customer } from "../account-settings";
import { Bot } from "../bot";
import { forkJoin } from "rxjs";
import { UserLanguage } from "src/app/shared/enums/user-language.enum";
import { AppConfig } from "src/app.config";
import { BotSubscription } from "../bot-settings/pricing/bot-subscription.model";
import { Plan } from "src/app/modules/payment/services/plan.model";
@Injectable({
  providedIn: "root",
})
export class SegmentIoService {
  currentUser: Customer;
  currentBot: Bot;
  currentBotPlan: Plan;
  UserLanguage = UserLanguage;
  constructor(private segmentIoBackendService: SegmentIoBackendService) {}

  handelLogin(user) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    const trackTitle = "Log In";
    if (this.currentUser !== user) {
      this.currentUser = user;
    }

    this.loginIdentify();
    this.loginTrack(trackTitle);
  }

  loginTrack(trackTitle) {
    window.analytics.track(trackTitle, {
      user_Id: this.currentUser.Id,
      first_name: this.currentUser.FirstName,
      last_name: this.currentUser.LastName,
      email: this.currentUser.Email,
    });
  }

  loginIdentify() {
    const lastLogin = new Date();
    window.analytics.identify(this.currentUser.Id, {
      username: this.currentUser.FirstName + " " + this.currentUser.LastName,
      last_login: this.convertDate(lastLogin),
      email: this.currentUser.Email,
      language: this.getCurrentLanguage(this.currentUser.SelectedLanguage),
    });
  }

  handelSignUp(user) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    const trackTitle = "Signed Up";
    if (this.currentUser !== user) {
      this.currentUser = user;
    }

    this.signUpIdentify();
    this.loginTrack(trackTitle);
  }

  signUpIdentify() {
    const lastLogin = new Date();

    window.analytics.identify(this.currentUser.Id, {
      username: this.currentUser.FirstName + " " + this.currentUser.LastName,
      email: this.currentUser.Email,
      last_login: this.convertDate(lastLogin),
      signup_date: this.convertDate(lastLogin),
      language: this.getCurrentLanguage(this.currentUser.SelectedLanguage),
    });
  }

  handelBotState(trackTitle, user_Id, bot) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.segmentIoBackendService.getBotSubscription(bot.Id).subscribe((botSubscription: BotSubscription) => {
      this.currentBot = bot;
      if (this.currentBotPlan && botSubscription.StripePlanId === this.currentBotPlan.StripePlanId && this.currentUser) {
        this.botIdentify();
        this.botTrack(trackTitle);
      } else if (!this.currentUser && user_Id && this.currentBotPlan && botSubscription.StripePlanId === this.currentBotPlan.StripePlanId) {
        this.segmentIoBackendService.getCustomerData(user_Id).subscribe((res: Customer) => {
          this.currentUser = res;
          this.botIdentify();
          this.botTrack(trackTitle);
        });
      } else if (this.currentUser && (!this.currentBotPlan || botSubscription.StripePlanId !== this.currentBotPlan.StripePlanId)) {
        this.segmentIoBackendService.getPlanById(botSubscription.StripePlanId).subscribe((res: Plan) => {
          this.currentBotPlan = res;
          this.botIdentify();
          this.botTrack(trackTitle);
        });
      } else {
        forkJoin(
          this.segmentIoBackendService.getCustomerData(user_Id),
          this.segmentIoBackendService.getPlanById(botSubscription.StripePlanId)
        ).subscribe(([user, plan]) => {
          this.currentUser = <Customer>user;
          this.currentBotPlan = <Plan>plan;
          this.botIdentify();
          this.botTrack(trackTitle);
        });
      }
    });
  }

  botIdentify() {
    window.analytics.identify(this.currentUser.Id, {
      plan: this.currentBotPlan.Title,
    });
  }

  botTrack(trackTitle) {
    window.analytics.track(trackTitle, {
      user_Id: this.currentUser.Id,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      plan: this.currentBotPlan.Title,
      page_id: this.currentBot.Facebook ? this.currentBot.Facebook.PageId : null,
    });
  }

  handelBroadcast(user_Id, bot, broadcast) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.currentBot = bot;
    if (!this.currentUser && user_Id) {
      this.segmentIoBackendService.getCustomerData(user_Id).subscribe((res: Customer) => {
        this.currentUser = res;
        this.broadcastTrack(broadcast);
      });
    } else {
      this.broadcastTrack(broadcast);
    }
  }

  broadcastTrack(broadcast) {
    window.analytics.track("Broadcast Published", {
      user_Id: this.currentUser.Id,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      broadcast_id: broadcast.Id,
    });
  }

  handelBotEditing(user_Id, trackTitle, botId) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    if (this.currentBot && this.currentBot.Id === botId && this.currentUser && this.currentUser.Id === user_Id) {
      this.botEditingTrack(trackTitle, user_Id);
    } else if ((!this.currentBot && botId) || (this.currentBot.Id !== botId && this.currentUser)) {
      this.segmentIoBackendService.getBot(botId).subscribe((bot: Bot) => {
        this.currentBot = bot;
        this.botEditingTrack(trackTitle, user_Id);
      });
    } else if (!this.currentUser && user_Id && this.currentBot && this.currentBot.Id !== botId) {
      this.segmentIoBackendService.getCustomerData(user_Id).subscribe((res: Customer) => {
        this.currentUser = res;
        this.botEditingTrack(trackTitle, user_Id);
      });
    } else {
      forkJoin(this.segmentIoBackendService.getBot(botId), this.segmentIoBackendService.getCustomerData(user_Id)).subscribe(([bot, user]) => {
        this.currentBot = <Bot>bot;
        this.currentUser = <Customer>user;
        this.botEditingTrack(trackTitle, user_Id);
      });
    }
  }

  botEditingTrack(trackTitle, user_Id) {
    window.analytics.track(trackTitle, {
      user_Id: user_Id,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
    });
  }

  handelCoupon(trackTitle, customer, botId, plan, coupon) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.currentUser = customer;
    this.currentBotPlan = plan;
    if ((!this.currentBot && botId) || this.currentBot.Id !== botId) {
      this.segmentIoBackendService.getBot(botId).subscribe((bot: Bot) => {
        this.currentBot = bot;
        this.couponTrack(trackTitle, coupon);
      });
    } else {
      this.couponTrack(trackTitle, coupon);
    }
  }

  couponTrack(trackTitle, coupon) {
    window.analytics.track(trackTitle, {
      user_Id: this.currentUser.Id,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      plan: this.currentBotPlan.Title,
      coupon_code: coupon.Code,
    });
  }

  handelUpgradePlan(trackTitle, customerId, bot, plan, oldPlan) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.currentBot = bot;
    this.currentBotPlan = plan;
    this.changePlanIdentify(customerId);
    this.upgradePlanTrack(trackTitle, customerId, oldPlan);
  }

  upgradePlanTrack(trackTitle, customerId, oldPlan) {
    window.analytics.track(trackTitle, {
      user_Id: customerId,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      plan: this.currentBotPlan.Title,
      old_plan: oldPlan.Title,
      price: this.currentBotPlan.Price,
    });
  }

  handelDowngradePlan(trackTitle, customerId, bot, plan, oldPlan, selectedReason, downgradeDescription) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.currentBot = bot;
    this.currentBotPlan = plan;
    this.changePlanIdentify(customerId);
    this.downgradePlanTrack(trackTitle, customerId, oldPlan, selectedReason, downgradeDescription);
  }

  changePlanIdentify(customerId) {
    window.analytics.identify(customerId, {
      plan: this.currentBotPlan.Title,
    });
  }

  downgradePlanTrack(trackTitle, customerId, oldPlan, selectedReason, downgradeDescription) {
    window.analytics.track(trackTitle, {
      user_Id: customerId,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      plan: this.currentBotPlan.Title,
      old_plan: oldPlan.Title,
      price: this.currentBotPlan.Price,
      selected_reason: selectedReason,
      description: downgradeDescription,
    });
  }

  handelBotPublishing(user_Id, trackTitle, bot, pageId, PageName) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    this.currentBot = bot;
    if (!this.currentUser && user_Id) {
      this.segmentIoBackendService.getCustomerData(user_Id).subscribe((res: Customer) => {
        this.currentUser = res;
        this.PublishingTrack(trackTitle, pageId, PageName);
      });
    } else {
      this.PublishingTrack(trackTitle, pageId, PageName);
    }
  }

  PublishingTrack(trackTitle, pageId, PageName) {
    window.analytics.track(trackTitle, {
      user_Id: this.currentUser.Id,
      bot_id: this.currentBot.Id,
      bot_name: this.currentBot.Name,
      page_name: PageName,
      page_id: pageId,
    });
  }
  handelLanguageChanging(user_Id, lang) {
    if (AppConfig.isOnPrem === true) {
      return;
    }
    if (!this.currentUser && user_Id) {
      this.segmentIoBackendService.getCustomerData(user_Id).subscribe((res: Customer) => {
        this.currentUser = res;
        this.languageIdentify(lang);
      });
    } else {
      this.languageIdentify(lang);
    }
  }

  languageIdentify(lang) {
    window.analytics.identify(this.currentUser.Id, { Language: lang });
  }

  changeAccountInfoIdentify(user) {
    this.currentUser = user;

    window.analytics.identify(this.currentUser.Id, {
      username: this.currentUser.FirstName + " " + this.currentUser.LastName,
      email: this.currentUser.Email,
    });
  }

  convertDate(date) {
    return (
      [date.getMonth() + 1, date.getDate(), date.getFullYear()].join("/") + " " + [date.getHours(), date.getMinutes(), date.getSeconds()].join(":")
    );
  }

  getCurrentLanguage(lang: number) {
    switch (lang) {
      case UserLanguage.Arabic:
        return "ar";
      case UserLanguage.English:
        return "en";
      case UserLanguage.French:
        return "fr";
      default:
        return "en";
    }
  }
}

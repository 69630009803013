import { PlaygroundFlow } from '../..';
import { Injectable } from '@angular/core';
import { FoldersManagementService } from '../folders-management';
import { AppTranslateService } from '../../translation/app-translate.service';
import { LocalStorageService } from 'src/app/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FlowsManagementService {
  private currentFlowId: number;
  private currentFlow: PlaygroundFlow;
  private allBotFlows: PlaygroundFlow[];

  private updatedFlow: PlaygroundFlow;
  currentBotFlowsUpdate = new Subject<{ updatedFlows: PlaygroundFlow[] }>();
  currentFlowUpdate = new Subject<{ newFlow: PlaygroundFlow }>();
  currentFlowUpdated = new Subject<{ Flow: PlaygroundFlow }>();
  constructor(private foldersManagement: FoldersManagementService,
    public appTranslateService: AppTranslateService,
    private localStorage: LocalStorageService) { }


  IsBroadcastFlow = false;
  /**
   * sets current flow id
   * @param currentFlowID: flow ID of current flow
   */
  setCurrentFlowId(currentFlowID: number) {
    this.currentFlowId = currentFlowID;
  }

  /**
 * sets current flow with object send in function
 * sets current flow Id with flow shown in flow editor
 * @param currentFlow : current flow appears to user in flow editor
 */
  setCurrentFlowWithoutEventEmitter(currentFlow: PlaygroundFlow) {
    this.currentFlow = currentFlow;
    this.currentFlowId = currentFlow.Id;
  }
  /**
   * sets current flow with object send in function
   * sets current flow Id with flow shown in flow editor
   * emits event OnCurrentFlowUpdate so that any chnage in flow will be listend to
   * @param currentFlow : current flow appears to user in flow editor
   */
  setCurrentFlow(currentFlow: PlaygroundFlow) {
    this.currentFlow = currentFlow;
    this.currentFlowId = currentFlow.Id;

    if (currentFlow.BotId !== 0 || currentFlow.FolderId !== 0 || currentFlow.Name != null) {
      this.localStorage.setCurrentFlow(currentFlow);
    }

    this.currentFlowUpdate.next({ newFlow: this.currentFlow });
    /* this.updateCurrentFlow(); */
  }
   /**
    * 
    * @param updatedFlow 
    * check validation for flow name after updated and the response with isValidFlow equal false from the backend side
    */
  setUpdatedFlow(updatedFlow: PlaygroundFlow) {
    this.updatedFlow = updatedFlow;
    this.currentFlowUpdated.next({ Flow: this.updatedFlow });
  }

  getUpdatedFlow(){
    return this.updatedFlow;
  }

  /* updateCurrentFlow(){
    this.onCurrentFlowUpdate.emit({newFlow:this.currentFlow});
  } */

  /**
   * sets all bot flows with flows objects
   * emits event onCurrentBotFlowsUpdate so that any change in botFlows will be listened to
   * @param botFlows :flows of bot
   */
  setBotFlows(botFlows: PlaygroundFlow[]) {
    this.allBotFlows = botFlows;
    this.currentBotFlowsUpdate.next({ updatedFlows: this.allBotFlows });
  }
  /**
   * updates bot flows by getting all flows in folders and putting them in bot flows
   */
  getUpdateBotFlows() {
    this.currentBotFlowsUpdate.next({ updatedFlows: this.getBotFlows() });
    return this.allBotFlows;
  }
  /**
   * gets bot flows
   */
  getBotFlows(getDeleted = false) {
    const folders = this.foldersManagement.getCurrentFolders();
    let allBotFoldersFlows = [];

    folders.forEach(folder => {
      folder.Flows = getDeleted ? folder.Flows : folder.Flows.filter(flow => flow.IsDeleted === false);
      allBotFoldersFlows = allBotFoldersFlows.concat(folder.Flows);
    });

    this.allBotFlows = allBotFoldersFlows;

    return this.allBotFlows;
  }
  /**
   * deletes flow from bot flows
   * @param deletedFlow : flow that will be deleted
   */
  deleteFlow(deletedFlow: PlaygroundFlow) {
    let botFlows = this.getUpdateBotFlows();
    const index = botFlows.indexOf(botFlows.find(f => f.Id === deletedFlow.Id));
    if (index !== -1) {
      botFlows[index].IsDeleted = true;
      botFlows = botFlows.filter(f => f.IsDeleted === false);
      this.setBotFlows(botFlows);
    }


  }


  /**
   * updates flow name in bot flows
   */
  updateFlowName(updatedFlow: PlaygroundFlow) {
    const botFlows = this.getUpdateBotFlows();
    const index = botFlows.indexOf(botFlows.find(f => f.Id === updatedFlow.Id));
    if (index !== -1) {
      botFlows[index].Name = updatedFlow.Name;
      // this.mainService.setAllBotFlows(botFlows);
      this.setBotFlows(botFlows);
    }
  }

  /**
   * add flow in flows list
   * @param newFlow: the flow that will be added to list of flows
   */
  addFlow(newFlow: PlaygroundFlow) {
    this.allBotFlows = this.getUpdateBotFlows();
    this.allBotFlows.push(newFlow);
    // this.setBotFlows(this.allBotFlows);
  }
  /**
   * check name of flow exist before or not
   */
  checkFlowName() {
    let allBotFlows = this.getUpdateBotFlows();
    let max = -1;
    let matchingName: string;

    if (this.appTranslateService.currentLanguage === "en") {
      matchingName = "New Flow";
    } else if (this.appTranslateService.currentLanguage === "fr") {
      matchingName = "Nouveau Séquence";
    } else if (this.appTranslateService.currentLanguage === "ar") {
      matchingName = "مجموعة رسائل جديدة";
    }

    let flowNames = allBotFlows.map((f) => f.Name);

    flowNames = flowNames
      .filter((fn) => fn.startsWith(matchingName))
      .sort((a, b) => {
        let aNum = 0;
        let bNum = 0;
        const aArr = a.split(" ");
        const bArr = b.split(" ");
        if (this.appTranslateService.currentLanguage === "en" || this.appTranslateService.currentLanguage === "fr") {
          if (aArr.length === 3) {
            aNum = +aArr[2];
          }
          if (bArr.length === 3) {
            bNum = +bArr[2];
          }
        } else if (this.appTranslateService.currentLanguage === "ar") {
          if (aArr.length === 4) {
            aNum = +aArr[3];
          }
          if (bArr.length === 4) {
            bNum = +bArr[3];
          }
        }
        return aNum - bNum;
      });

    for (let i = 0; i < flowNames.length; i++) {
      if (!flowNames.includes(`${matchingName} ${i + 1}`)) {
        return i;
      }
    }
    return max;
  }
  /**
   * gets current flowId
   */
  getCurrentFlowId() {
    return this.currentFlowId;
  }

  /**
  * gets current flow
  */
  getCurrentFlow() {
    return this.currentFlow;
  }

  getNumberOfFlows() {
    let numOfFlows = 0;
    this.foldersManagement.currentFolders.forEach(folder => {
      numOfFlows = folder.Flows ? numOfFlows + folder.Flows.length : numOfFlows;
    });
    return numOfFlows;
  }
}

import { Type } from "@angular/core";
import { Bot } from "../../bot/bot.model";

export interface Team {
  Name: string;
  Color: string;
  Type: TagType;
  IsValid: any;
  Conflict: any;
  BotId: number | string;
  Bot: Bot | any;
  CustomerTeams: any;
  HandoverMessages: any;
  Id;
  IsDeleted;
  CustomerAuthId;
  Customer;
  CreatedAt;
  TagModels: Team[];
  TotalPages;
  selected: boolean;
}

export enum TagType {
  Team,
  Chat
}

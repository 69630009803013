import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export class Alert {
  message: string;
  type: AlertTypes;
  IsNotification: boolean; // disapear after 3000 ms
  AllowClose: boolean;
  hide: boolean;
  isPlan: boolean;
  isBlackText: boolean;
  AllowReload: boolean;
  isExport: boolean;

  constructor(message, type, IsNotification, AllowClose, isPlan, isBlackText, AllowReload, isExport) {
    this.message = message;
    this.type = type;
    this.IsNotification = IsNotification;
    this.AllowClose = AllowClose;
    this.hide = false;
    this.isPlan = isPlan;
    this.isBlackText = isBlackText;
    this.AllowReload = AllowReload;
    this.isExport = isExport;
  }
}

export enum AlertTypes {
  warning,
  success,
  failure,
  anouncement,
  alert,
}

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  private alertSubject = new Subject();
  alertState = this.alertSubject.asObservable();
  private alert: Alert;
  private alerts = [];

  constructor() {}

  setAlert(
    message: string,
    type: AlertTypes,
    IsNotification = true,
    AllowClose = true,
    isPlan = false,
    isBlackText = false,
    AllowReload = false,
    isExport = false
  ): Alert {
    const alertIndex = this.findAlertToRemove(message);
    const alert = new Alert(message, type, IsNotification, AllowClose, isPlan, isBlackText, AllowReload, isExport);
    this.alert = alert;
    if (this.alert.IsNotification) {
      setTimeout(() => {
        this.alert.hide = true;
        this.hideAlert(message);
      }, 3000);
    }

    if (alertIndex === -1 && (IsNotification || AllowClose)) {
      this.alerts.push(this.alert);
    } else if (!IsNotification && !AllowClose) {
      this.alerts.unshift(this.alert);
    }
    this.alertSubject.next({ alerts: this.alerts });
    return alert;
  }

  hideAlert(message: string) {
    if (!message) return;
    const alertIndex = this.findAlertToRemove(message);
    if (alertIndex > -1) {
      this.alerts.splice(alertIndex, 1);
    }
    this.alertSubject.next({ alerts: this.alerts });
  }

  hidePlanAlerts() {
    this.alerts = this.alerts.filter((alert: Alert) => alert.isPlan === false);
    this.alertSubject.next({ alerts: this.alerts });
  }

  checkIfAlertExist(message: string): boolean {
    const alert: number = this.findAlertToRemove(message);
    return this.alerts.indexOf(alert) >= 0 ? true : false;
  }

  findAlertToRemove(message: string): number {
    const alert: number = this.alerts.find(
      (alrt: Alert) =>
        alrt.message
          .replace(/\s/g, "")
          .replace(/\r?\n|\r/g, "")
          .toLocaleLowerCase() ===
        message
          .replace(/\s/g, "")
          .replace(/\r?\n|\r/g, "")
          .toLocaleLowerCase()
    );
    return this.alerts.indexOf(alert);
  }

  removeAllAlerts() {
    this.alerts = [];
  }
}

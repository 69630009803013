export enum ButtonType {
  Flow = 0,
  URL = 1,
  Phone = 2,
  // Share= 3
  BackToBot = 4,
}

export enum QuickRepliesType {
  Flow = 1,
  Location = 2,
  Email = 3,
  Phone = 4,
  Scenario = 5,
  ReturnToBot = 6,
}

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { Plan } from "./plan.model";
import { EndPoint } from "src/EndPoints/EndPoint";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  headers = new HttpHeaders().set("content-type", "application/json");
  BaseUrl: string = AppConfig.BaseUrl + "/" + EndPoint.BACKEND_PATHS.BillingPlan;
  baseUrl: string = AppConfig.BaseUrl;
  constructor(private _http: HttpClient) {}
  getPlans(filterByBotOwnerCurrency = "false") {
    return this._http.get(this.BaseUrl, {
      params: { filterByBotOwnerCurrency },
    });
  }

  getBotRoles() {
    return this._http.get(this.baseUrl + "/" + EndPoint.BACKEND_PATHS.DomainRole);
  }
}

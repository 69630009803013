import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app.config';
import { EndPoint } from 'src/EndPoints/EndPoint';

@Injectable({
  providedIn: 'root',
})
export class SegmentIoBackendService {
  headers = new HttpHeaders().set('content-type', 'application/json');

  baseUrl = AppConfig.BaseUrl + '/';
  constructor(private _http: HttpClient) {}

  getCustomerData(id: number) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.CustomerData + '/' + id);
  }

  getBot(id: number) {
    return this._http.get(
      this.baseUrl + EndPoint.BACKEND_PATHS.Bot + '/' + id  ,
      { headers: this.headers }
    );
  }

  getPlanById(StripePlanId: string) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.BillingPlan + '/' + StripePlanId);
  }

  getBotSubscription(botId: number) {
    const headers = new HttpHeaders().set('x-bot', botId.toString());
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.BotSubscription + '/' + botId, {headers: headers});
  }
}

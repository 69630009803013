import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfig } from './app.config';
import { AppConfigs } from './Configs/AppConfigs';

const appConfigs = new AppConfigs()

if (environment.production) {
  enableProdMode();
}

appConfigs.checkDomain(() => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {});
})



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer, CustomerInfo } from './customer.model';
import { AppConfig } from './../../../../app.config';
import { EndPoint } from 'src/EndPoints/EndPoint';
import { UserLanguage } from 'src/app/shared/enums/user-language.enum';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  headers = new HttpHeaders().set('content-type', 'application/json');

  baseUrl = AppConfig.BaseUrl + '/';

  userDomain = window.location.host;

  constructor(private _http: HttpClient) {
  }

  getCustomer(id: number) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.CustomerbyId + '/' + id);
  }

  getAuthToken() {
    return this._http.get(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.GenerateAuthToken + '/' + 'GetAuthToken');
  }

  deleteAuthToken() {
    return this._http.delete(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.GenerateAuthToken + '/' + 'DeleteAuthToken');
  }
  
  createAuthToken() {
    return this._http.post(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.GenerateAuthToken + '/' + 'CreateAuthToken',{});
  }

  getCustomerData(id: number) {

    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.CustomerData + '/' + id);
  }

  getAllCustomers() {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.Customer);
  }

  addCustomer(customer: Customer) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.Customer, customer, { headers: this.headers });
  }

  editCustomer(customer: Customer) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.Customer, customer, { headers: this.headers });
  }

  editCustomerInfo(customerInfo: CustomerInfo) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.Customer + "/Information", customerInfo, { headers: this.headers });
  }

  // editCustomerBilling(customer: Customer) {
  //   return this._http.put(this.baseUrl + 'billingMode', customer, { headers: this.headers });
  // }

  getCustomerEmail(email) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.UpdateEmail + '/' + email, {headers: new HttpHeaders().set('x-user-domain', this.userDomain )});
  }

  verifyCustomerEmail(email) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.VerifyCustomerEmail + '/' + email,
    {headers: new HttpHeaders().set('x-user-domain', this.userDomain )});
  }

  confirmEmail(token) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.ConfirmedEmail,
      { token: token }, {headers: new HttpHeaders().set('x-user-domain', this.userDomain )}
    );
  }

  verifyNewEmail(NewEmail, CurrentEmail, Password) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.SendVerifyNewEmail,
      { NewEmail: NewEmail, CurrentEmail: CurrentEmail, Password: Password },
      {headers: new HttpHeaders().set('x-user-domain', this.userDomain )});
  }

  confirmNewEmail(token) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.CustomerEmail,
      { token: token }
    );
  }


  saveUserLanguage(selectedLang: UserLanguage, EncryptedId: string): Observable<boolean> {
    const customer = new Customer();
    customer.EncryptedId = EncryptedId;
    customer.SelectedLanguage = selectedLang;
    return this._http.put<boolean>(this.baseUrl + EndPoint.BACKEND_PATHS.SaveSelectedLanguage, customer, { headers: this.headers });
  }

  confirmEmailChannel(token) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.EmailChannel + "/VerifyEmail",
      { token: token }, {headers: new HttpHeaders().set('x-user-domain', this.userDomain )}
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastCreationActivateGuard implements CanActivate {

  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (next.params.step > 4 || next.params.step < 1) {
        this.router.navigate(['/broadcast/create/' + next.params.id + '/' + next.params.broadcastId + '/step/1']);
        return false;
      } else { return true; }

  }
}

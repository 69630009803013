export interface LoginParams {
    Code: string;
    State: string;
    Lang: number;
    ThirdPartyType: ThirdPartyOAuthType;
}

export enum ThirdPartyOAuthType {
    Non,
    Facebook,
    Google
}
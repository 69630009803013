import { WhatsAppButton } from "./../../play-ground/whatsApp-button/whatsApp-button.model";
import { Injectable } from "@angular/core";
import {
  Gallery,
  PlaygroundList,
  TextMessage,
  QuickReplyMessage,
  PlaygroundImage,
  FlowMessageModel,
  MessageButton,
  PlaygroundAttachment,
} from "../../play-ground";

import { Validator } from "validator.ts/Validator";
import { ButtonType, MessageType, FileType } from "../../../shared/enums";

import { GoToFlow } from "../../play-ground/go-to-flow/go-to-flow.model";
import { UserAttribute } from "../../user-attributes/user-attribute.model";
import { Comparer } from "src/app/shared/enums/compare-enum";
import { UserInput } from "../../play-ground/user-input/user-input.model";
import { SetAttribute } from "../../play-ground/set-attribute/set-attribute.model";
import { AttributeType } from "src/app/shared/enums/attribute-type.enum";
import { Typing } from "./../../play-ground/typing/typing.model";
import { AppConfig } from "src/app.config";
import { Handover } from "src/app/shared/models/HumanHandOverModels/Handover.model";
import { TimerScheduler } from "src/app/shared/models/HumanHandOverModels/TimerScheduler.model";
import { BehaviorSubject, Subject } from "rxjs";
import { JsonApi } from "../../play-ground/json-api/json-api.model";
import { WhatsappList } from "../../play-ground/whatsapp-list/whatsapp-list-modal";
import { HeaderTypes } from "src/app/shared/enums/whatsApp-button-header-types.enum";
import { WAFlowScreen, WhatsappFlowsMessageBody } from "src/app/modules/layout/playground/models/WhatsappFlowsMessage";

@Injectable({
  providedIn: "root",
})
export class FlowMessagesManagementService {
  currentFlowsMessages: FlowMessageModel[];
  goToFlowConditions = [];
  Headers = [];
  validator: Validator = new Validator();

  // tslint:disable-next-line: max-line-length
  phoneRegex =
    "^(\\+|00)(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{5,15}$";
  // tslint:disable-next-line: max-line-length
  urlRegex = new RegExp(
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  );
  numberRegex = new RegExp(/^[0-9]+$/);
  // tslint:disable-next-line: max-line-length
  emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  datetimeRegex = new RegExp(/.*/);
  locationRegex = new RegExp(/.*/);
  textRegex = new RegExp(/.*/);
  // tslint:disable-next-line: max-line-length
  urlImageRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi;
  dateTimeRegex = /(0[1-9]|[1-2][0-9]|3[0-1])[- /.](0[1-9]|1[0-2])[- /.][0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9] (P|p|A|a)(M|m)/;
  attrRegex = new RegExp(/({{[^\s{}]*?}})/g);
  jsonUrlRegex = new RegExp(/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?)+$/gm);
  jsonHeaderKeyRegex = new RegExp(/^[a-zA-Z 0-9\!\#\$\%\&\'\*\+\-\`\|\~\^\_\.\/]+$/gm);
  jsonHeaderValueRegex = new RegExp(/^[a-zA-Z 0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\]\\\^\_\`\{\}\|\s\t]+$/gm);
  dateAndTimeRegex = /^(\d{4})\-(\d{2})\-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  messageUpdate = new Subject<{ flowMessages: FlowMessageModel[] }>();
  messageOrderChanged = new Subject<void>();
  currentFlowId: number;
  updateFlowMsg: boolean;
  headerType = HeaderTypes;

  private _whatsappFlowsScreensSub = new BehaviorSubject<WAFlowScreen[]>([]);

  get whatsappFlowsScreens() {
    return this._whatsappFlowsScreensSub.getValue();
  }

  setWhatsappFlowsScreens(whatsappFlowsScreens: WAFlowScreen[]) {
    this._whatsappFlowsScreensSub.next(whatsappFlowsScreens);
  }

  constructor() {}
  /**
   * sets flow Messages
   * @param flowMessages : messages of flow
   */
  setFlowMessages(flowMessages: FlowMessageModel[], id?) {
    if (id && id !== this.currentFlowId) {
      return;
    }
    flowMessages = flowMessages.filter((message) => message.IsDeleted === false);
    this.currentFlowsMessages = [...flowMessages];
    if (this.currentFlowsMessages.length) {
      this.currentFlowId = this.currentFlowsMessages[0].FlowId;
    }
    this.emitFlowMessageUpdate();
  }
  /**
   * add flow Message object in flow Messages list
   * @param flowMessage :flow Message object that will be added
   */
  addFlowMessage(flowMessage: FlowMessageModel) {
    /*
      let currentFlow=this.flowsManagementService.getCurrentFlow();
      currentFlow.FlowMessages.push(flowMessage);
      this.flowsManagementService.setCurrentFlow(currentFlow);
      */
    this.currentFlowsMessages.push(flowMessage);
    this.emitFlowMessageUpdate();
    return this.currentFlowsMessages;
  }

  /**
   * check validty of gallery based on title with subtitle
   * or title with image
   * or title with buttons
   * @param gallary: gallary of flowMessage
   */
  private checkGalleryCardValidty(gallary: Gallery) {
    const galleryCards = gallary.GalleryCards;
    for (const galleryCard of galleryCards) {
      const validTitle = galleryCard.Title != null && galleryCard.Title !== "" && galleryCard.Title.length <= AppConfig.TitleMaxLength;
      const validSubtitle = galleryCard.Subtitle !== "" && galleryCard.Subtitle != null && galleryCard.Subtitle.length <= AppConfig.SubTitleMaxLength;
      const validImage = galleryCard.ImageUrl != null && galleryCard.ImageUrl !== "" && galleryCard.ImageUrl.length <= AppConfig.urlMaxLength;
      let validButtons = true;

      if (galleryCard.Buttons.length > 0) {
        validButtons = this.checkButtonValidity(galleryCard.Buttons);

        if (!(validTitle && validButtons && (validButtons || validSubtitle || validImage))) {
          return false;
        }
      } else {
        if (!(validTitle && (validSubtitle || validImage))) {
          return false;
        }
      }
    }

    return true;
  }
  /**
   * check validty of List based on
   *  title with subtitle
   *  or title with image
   *  or title with buttons
   * @param ListTemplate:
   */
  private checkListValidty(listTemplate: PlaygroundList) {
    const listItems = listTemplate.ListItems;
    for (const listItem of listItems) {
      const validTitle = listItem.Title != null && listItem.Title !== "";
      const validSubtitle = listItem.Subtitle !== "" && listItem.Subtitle != null;
      const validImage = listItem.ImageUrl != null && listItem.ImageUrl !== "";
      let validButtons = true;

      if (listItem.Button.length > 0) {
        validButtons = this.checkButtonValidity(listItem.Button);
        if (!(validTitle && validButtons) && (validButtons || validSubtitle || validImage)) {
          return false;
        }
      } else {
        if (!(validTitle && (validSubtitle || validImage))) {
          return false;
        }
      }
    }
    const validDefaultButton = this.checkButtonValidity(listTemplate.DefaultButton);
    return validDefaultButton;
  }

  /**
   * checks text message validty according to ext and buttons validity
   * @param textMessage:
   */
  private checkTextMessageValidty(textMessage: TextMessage) {
    if (textMessage.Text == null || textMessage.Text === "" || textMessage.Text.length > AppConfig.TextMessageMaxLength) {
      return false;
    }

    const validButtons = this.checkButtonValidity(textMessage.Buttons);
    return validButtons;
  }

  /**
   * checks validity of buttons according to type of button
   * ckecks title first as all of them  must have title
   * then if share continue then check terget as target must not be null in flow phone and url types
   * @param buttons:buttons of message
   */
  private checkButtonValidity(buttons: MessageButton[]) {
    for (const button of buttons) {
      if (button.Title == null || button.Title === "" || button.Title.length > AppConfig.ButtonTitleMaxLength) {
        return false;
      } else if (button.Type === ButtonType.Flow && (button.FlowId == null || !button.Target)) {
        return false;
      } else if (button.Type === ButtonType.Phone && (button.Phone == null || button.Phone === "" || !button.Phone.match(this.phoneRegex))) {
        return false;
      } else if (
        button.Type === ButtonType.URL &&
        !(button.URL && button.URL.toLowerCase().startsWith("tel")) &&
        (button.URL == null || button.URL === "" || !this.validator.isURL(button.URL.toLowerCase(), {})) &&
        (!(button.URL && button.URL.toLowerCase().match(this.attrRegex)) || button.URL.length > AppConfig.urlMaxLength)
      ) {
        return false;
      } else if (button.Type === ButtonType.BackToBot) {
        return true;
      }
    }
    return true;
  }

  /**
   *
   * @param quickReplyMessage
   */
  private checkQuickReplyValidty(quickReplyMessage: QuickReplyMessage) {
    const buttons = quickReplyMessage.Buttons;

    if (buttons.length === 0) {
      return false;
    }

    for (const button of buttons) {
      if (
        button.Title == null ||
        button.Title === "" ||
        button.Title.length > AppConfig.ButtonTitleMaxLength ||
        button.Target == null ||
        button.Target === ""
      ) {
        return false;
      }
    }
    return true;
  }
  /**
   * checks attachment validity
   * @param attachment: image attachment of flow message
   */
  checkAttachmentValidity(attachment: PlaygroundAttachment) {
    if (attachment.Url === null || attachment.Url === "") {
      return false;
    }
    return this.getAttachmentMimeType(attachment) !== "";
  }
  /**
   * check validation for whatsAppButton message
   * @param whatsAppButton
   * @returns
   */
  checkWhatsAppButtonsValidity(whatsAppButton: WhatsAppButton) {
    const buttons = whatsAppButton.Buttons;

    if (
      buttons.length === 0 ||
      whatsAppButton.Body === null ||
      whatsAppButton.Body === "" ||
      !whatsAppButton.haveWhatsApp ||
      (whatsAppButton.Header.Type === this.headerType.Text && whatsAppButton.Header.Text === null) ||
      whatsAppButton.Header.Text === "" ||
      (whatsAppButton.Header.Type === this.headerType.Image && whatsAppButton.Header.ImageUrl === null)
    ) {
      return false;
    }

    for (const button of buttons) {
      if (
        button.Title == null ||
        button.Title === "" ||
        button.Title.length > AppConfig.ButtonTitleMaxLength ||
        button.Target == null ||
        button.Target === ""
      ) {
        return false;
      }
    }

    return true;
  }

  getAttachmentMimeType(attachment: PlaygroundAttachment) {
    if (attachment.Url === null || attachment.Url === "") {
      return "";
    }
    const a = attachment.FileName.split("."); // puts all sub-Strings delimited by . into an Array
    let lastElement = a[a.length - 1]; // gets the last element of that Array
    let mimeType;
    if (attachment.FileType === FileType.Video) {
      mimeType = AppConfig.VideoAcceptedFormats;
    } else if (attachment.FileType === FileType.Audio) {
      mimeType = AppConfig.AudioAcceptedFormats;
    } else if (attachment.FileType === FileType.File) {
      mimeType = AppConfig.FileAcceptedFormats;
    } else if (attachment.FileType === FileType.Image) {
      // lastElement = attachment.MimeType;
      mimeType = AppConfig.ImageAcceptedFormats;
    }

    const wordExist = mimeType.some(function (e) {
      return e === lastElement.toLocaleLowerCase();
    });
    if (wordExist === true) {
      return lastElement;
    } else {
      return "";
    }
  }

  /**
   * checks handover validity
   * @param handover: handover of flow message
   */
  private checkHandOverValidity(handover: Handover, time: TimerScheduler, botText: TextMessage) {
    if (handover.SelectedSecondaryAppId === null || (handover.KeyWords === null && time.TimeStatus === false && botText.Text === "")) {
      return false;
    }
    return true;
  }

  getNextCondition(condition) {
    this.goToFlowConditions.push(condition);
    if (condition.NextCondition) {
      this.getNextCondition(condition.NextCondition);
    }
  }

  /**
   * @description Checks json api message validity.
   * @param json The JsonAPI Message to validate.
   * @returns boolean true if the message is valid, false otherwise.
   */
  private checkJsonApiValidity(json: JsonApi) {
    // TODO: validate header key and value input characters according to ISO standards
    this.Headers = JSON.parse(json.Headers);

    // Validate the URL
    if (!this.validateJsonUrl(json.URL)) {
      return false;
    }

    // Validate the Headers
    if (this.Headers && this.Headers.length) {
      for (const header of this.Headers) {
        if (
          !this.isValidJsonHeaderKey(header.Key) ||
          (header.Value &&
            ((!this.isValidJsonHeaderValue(header.Value) && !this.isValidAttr(header.Value)) ||
              header.Value.length > AppConfig.jsonHeaderValueMaxLength))
        ) {
          return false;
        }
      }
    }

    // Validate the Json Body, only if method is POST or PUT
    if (json.Method === "POST" || json.Method === "PUT") {
      if (!this.validateJsonBody(json.Body)) {
        return false;
      }
    }

    // Return true if all checks are passed
    return true;
  }

  /**
   * @description Checks Whatsapp flows message validity.
   * @param json The JsonAPI Message to validate.
   * @returns boolean true if the message is valid, false otherwise.
   */
  private checkWhatsappFlowsValidity(json: JsonApi): boolean {
    if (json && this.validateJsonBody(json.Body)) {
      const waFlowBody: WhatsappFlowsMessageBody = JSON.parse(json.Body);

      if (waFlowBody && waFlowBody.Body && waFlowBody.Button && waFlowBody.Button.Title && waFlowBody.Button.FlowId) {
        if (this.whatsappFlowsScreens && this.whatsappFlowsScreens.length === waFlowBody.Screens.length) {
          return this.whatsappFlowsScreens.every((screen) => {
            const currScreen = waFlowBody.Screens.find((s) => s.Id === screen.Id);
            if (!screen.Components || screen.Components.length === currScreen.Components.length) {
              return true;
            }

            return false;
          });
        }
      }
    }
    return false;
  }

  /**
   * @description Validates the provided JSON body on the following conditions:
   * Provided body is not undefined.
   *
   * Ignore the UserAttr from the validation if exist
   *
   * Provided body length is less or equal to the jsonBodyMaxLength property in the AppConfig.
   *
   * Provided body follows the JSON specifications.
   * @param body The string JSON body to validate.
   * @returns true if the json string is valid, false otherwise.
   */
  validateJsonBody(body: string) {
    body = this.emptyUserAttr(body);
    if (!body || (body.length <= AppConfig.jsonBodyMaxLength && this.isJson(body))) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Determines whether the provided json string is a valid JSON syntax.
   * @param body The string JSON body to validate.
   * @returns boolean true if the json string is a valid json syntax, false otherwise.
   */
  private isJson(body: string) {
    try {
      JSON.parse(body);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param text : the text that includes the user attribute
   * @returns the text without the user attribute string
   */
  private emptyUserAttr(text: string) {
    return text ? text.replace(this.attrRegex, "null") : "";
  }

  /**
   * @description Validates the provided JSON url on the following conditions:
   *  Provided url is not undefined.
   *
   * Provided url length is less or equal to the urlMaxLength property in the AppConfig.
   *
   * Provided url follows the JSON specifications.
   * @param url The string JSON url to validate.
   * @returns true if the url string is valid, false otherwise.
   */
  validateJsonUrl(url: string) {
    if (url && url.length <= AppConfig.urlMaxLength && (url.match(this.jsonUrlRegex) || url.match(this.attrRegex))) {
      return true;
    } else {
      return false;
    }
  }

  isValidJsonHeaderKey(value) {
    if ((value || "").match(this.jsonHeaderKeyRegex)) {
      return true;
    }
    return false;
  }

  isValidJsonHeaderValue(value) {
    if ((value || "").match(this.jsonHeaderValueRegex)) {
      return true;
    }
    return false;
  }

  isValidAttr(value) {
    if ((value || "").match(this.attrRegex)) {
      return true;
    }
    return false;
  }

  checkGoToFlowValidity(goToFlowMessage: GoToFlow) {
    if (!goToFlowMessage.NextFlowId || !goToFlowMessage.isValid) {
      return false;
    }

    return true;
  }

  checkUserInputValidty(userInput: UserInput) {
    const UserInputAttr: UserAttribute = userInput.UserAttribute;

    if (!UserInputAttr) {
      return false;
    }

    const hasUserAttr = !!userInput.UserAttributeId;
    const isCompareWithValueValid = !!UserInputAttr.CompareWith || +UserInputAttr.Condition === Comparer.None;
    const isValidationMessageValid =
      +UserInputAttr.Condition === Comparer.None ||
      (userInput.InvalidInputMessage || "").trim() !== "" ||
      UserInputAttr.AttributeType === AttributeType.Text;

    return !!(hasUserAttr && isCompareWithValueValid && isValidationMessageValid);
  }

  checkWhatsappListValidity(whatsappList: WhatsappList) {
    let isValidBtnHeaderAndBody =
      whatsappList.Body !== null && whatsappList.Button !== null && whatsappList.Body !== "" && whatsappList.Button !== "";
    let notValidSection = false,
      notValidRow = false;
    whatsappList.Sections.forEach((s) => {
      if (whatsappList.Sections.length === 0 || (!s.Title && whatsappList.Sections.length > 1)) {
        notValidSection = true;
      }
      s.Rows.forEach((r) => {
        if (!r.Title || !r.FlowId || s.Rows.length === 0 || r.Title.length > 24) {
          notValidRow = true;
        }
      });
    });
    return isValidBtnHeaderAndBody && !notValidRow && !notValidSection && whatsappList.haveWhatsApp;
  }

  checkAttributeValidity(userAttribute: UserAttribute, value: string) {
    if (userAttribute && value && value.trim()) {
      if (userAttribute.AttributeType === AttributeType.URL) {
        return this.isUrlValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Email) {
        return this.isEmailValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Number) {
        return this.isNumberValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Phone) {
        return this.isPhoneValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Text) {
        return this.isTextValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Location) {
        return this.isLocationValid(value);
      } else if (userAttribute.AttributeType === AttributeType.DateAndTime) {
        return this.isDateAndTimeValid(value);
      } else if (userAttribute.AttributeType === AttributeType.Regex) {
        return this.isTextValid(value);
      } else if (userAttribute.AttributeType === AttributeType.DATE) {
        return this.isDateOrTimeValid(value);
      } else if (userAttribute.AttributeType === AttributeType.TIME) {
        return this.isDateOrTimeValid(value);
      }
    } else {
      return false;
    }
  }

  checkTypingValidity(typing: Typing) {
    return !isNaN(typing.Duration) && typing.Duration >= 0.2 && typing.Duration <= 30;
  }

  /**
   * checks validity of message according to its type
   * @param flowMessage: flowMesage object
   */
  checkMessageValidty(flowMessage: FlowMessageModel) {
    if (flowMessage.IsDeleted) {
      return true;
    }
    if (flowMessage.ListTemplate != null) {
      return this.checkListValidty(flowMessage.ListTemplate);
    } else if (flowMessage.Gallery != null) {
      return this.checkGalleryCardValidty(flowMessage.Gallery);
    } else if (flowMessage.QuickReplyMessage != null && flowMessage.Handover === null) {
      return this.checkQuickReplyValidty(flowMessage.QuickReplyMessage) && this.checkBehavioralMessageValidity(flowMessage);
    } else if (flowMessage.WhatsAppButtonReply != null) {
      return this.checkWhatsAppButtonsValidity(flowMessage.WhatsAppButtonReply);
    } else if (flowMessage.MediaAttachment != null) {
      return this.checkAttachmentValidity(flowMessage.MediaAttachment);
    } else if (flowMessage.JsonApi != null && flowMessage.JsonApi.Type === 1) {
      return this.checkWhatsappFlowsValidity(flowMessage.JsonApi);
    } else if (flowMessage.JsonApi != null && flowMessage.JsonApi.Type === 0) {
      return this.checkJsonApiValidity(flowMessage.JsonApi);
    } else if (flowMessage.GoToFlow != null) {
      return this.checkGoToFlowValidity(flowMessage.GoToFlow);
    } else if (flowMessage.UserInput != null) {
      return this.checkUserInputValidty(flowMessage.UserInput) && this.checkBehavioralMessageValidity(flowMessage);
    } else if (flowMessage.SetAttribute != null) {
      return this.checkAttributeValidity(flowMessage.SetAttribute.UserAttribute, flowMessage.SetAttribute.Value);
    } else if (flowMessage.Handover != null) {
      return this.checkHandOverValidity(flowMessage.Handover, flowMessage.TimerSchedule, flowMessage.TextMessage);
    } else if (flowMessage.TextMessage != null) {
      return this.checkTextMessageValidty(flowMessage.TextMessage);
    } else if (flowMessage.Typing != null) {
      return this.checkTypingValidity(flowMessage.Typing);
    } else if (flowMessage.WhatsAppList !== null) {
      return this.checkWhatsappListValidity(flowMessage.WhatsAppList);
    } else {
      return false;
    }
  }

  /**
   * checks validity of all flow messages
   */
  checkFlowMessagesValidity() {
    const hasInvalidFlowMessage = !!this.currentFlowsMessages.find((m) => !m.IsValidMessage);

    const length = this.currentFlowsMessages.length;
    const isFlowEmptyWithQR = length === 1 && this.currentFlowsMessages[length - 1].Type === MessageType.QuickReply;

    // asd
    const isValidBehavioralMessage = this.checkAllBehavioralMessagesValidatity();
    // asd

    if (!hasInvalidFlowMessage && isValidBehavioralMessage && !isFlowEmptyWithQR) {
      return true;
    }
    return false;
  }

  /**
   * checks Behavioral content messages validity
   */
  checkBehavioralMessageValidity(message: FlowMessageModel, messagesSortedByOrder = this.getMessagesSortedByOrder()) {
    const index = messagesSortedByOrder.findIndex((flowMessage) => flowMessage.Id === message.Id);
    const prevMessage = messagesSortedByOrder[index - 1];

    if (!prevMessage) {
      return false;
    }

    const prevMessageType = prevMessage.Type;

    // If prev message is process content or behavioral (execluded QR because it's won't come before any message)
    if (
      prevMessageType === MessageType.Text ||
      prevMessageType === MessageType.Gallery ||
      prevMessageType === MessageType.List ||
      (prevMessageType === MessageType.JsonApi && prevMessage.JsonApi.Type === 1) ||
      prevMessageType === MessageType.MediaAttachment ||
      prevMessageType === MessageType.ImageAttachment ||
      prevMessageType === MessageType.AudioAttachment ||
      prevMessageType === MessageType.VideoAttachment ||
      prevMessageType === MessageType.WhatsAppButtonReply ||
      prevMessageType === MessageType.WhatsappList ||
      prevMessageType === MessageType.FileAttachment
    ) {
      return true;
    }

    return false;
  }

  checkAllBehavioralMessagesValidatity() {
    const flowBehaviorMessages = this.currentFlowsMessages.filter((message) => message.QuickReplyMessage || message.UserInput);
    const messagesSortedByOrder = this.getMessagesSortedByOrder();

    for (let i = 1; i < flowBehaviorMessages.length; i++) {
      if (!this.checkBehavioralMessageValidity(flowBehaviorMessages[i], messagesSortedByOrder)) {
        return false;
      }
    }

    return true;
  }

  getMessagesSortedByOrder() {
    return this.currentFlowsMessages.sort((msg1, msg2) => {
      return msg1.Order < msg2.Order ? -1 : 1;
    });
  }

  /**
   * updates flowMessage validity
   */
  updateFlowMessage(flowMessage: FlowMessageModel) {
    const flowMessageIndex = this.currentFlowsMessages.findIndex((flowMess) => flowMess.Id === flowMessage.Id);
    if (flowMessageIndex !== -1) {
      this.currentFlowsMessages[flowMessageIndex] = flowMessage;
      this.currentFlowsMessages = this.currentFlowsMessages.filter((message) => !message.IsDeleted);
      this.emitFlowMessageUpdate();
    }
  }

  emitFlowMessageUpdate() {
    return this.messageUpdate.next({ flowMessages: this.currentFlowsMessages });
  }

  emitFlowMessageOrderChange() {
    this.messageOrderChanged.next();
  }

  isNumberValid(number) {
    if ((number || "").match(this.numberRegex)) {
      return true;
    }
    return false;
  }

  isDateAndTimeValid(date) {
    if ((date || "").match(this.dateTimeRegex)) {
      return true;
    }
    return false;
  }

  isEmailValid(email) {
    if ((email || "").match(this.emailRegex)) {
      return true;
    }
    return false;
  }

  isUrlValid(url: string) {
    if (url && url.toLowerCase().match(this.urlRegex)) {
      return true;
    }
    return false;
  }
  isPhoneValid(phone) {
    if ((phone || "").match(this.phoneRegex)) {
      return true;
    }
    return false;
  }

  isDateValid(date) {
    if ((date || "").match(this.datetimeRegex)) {
      return true;
    }
    return false;
  }

  isLocationValid(location) {
    if ((location || "").match(this.locationRegex)) {
      return true;
    }
    return false;
  }
  isTextValid(text) {
    if ((text || "").match(this.textRegex)) {
      return true;
    }
    return false;
  }

  isDateOrTimeValid(time) {
    if ((time || "").match(this.dateAndTimeRegex)) {
      return true;
    }
    return false;
  }
  /**
   * gets flow Messages
   */
  getFlowMessages() {
    return this.currentFlowsMessages;
  }
}

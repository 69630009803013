import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class AppCookiesService {

  constructor(private cookieService: CookieService,
  ) { }

  setSignedInCookie() {
    if (location.origin === 'https://platform.widebot.net') {
      this.cookieService.set('isSignedIn', 'true', 365, '/', '.widebot.net');
    }
  }
  setProfilePic(profilePic) {
    this.cookieService.set('profilePicture', profilePic, 365, '/', '.widebot.net');

  }
  deleteSignedInCookie() {
    this.cookieService.delete('isSignedIn', '/', '.widebot.net');
    this.cookieService.delete('profilePicture', '/', '.widebot.net');
  }

}


export enum AttributeType {
    Text= 1,
    Phone,
    Email,
    Number,
    Location,
    DateAndTime,
    URL,
    Regex,
    SELECTED,
    DATE,
    TIME,
}
export enum BotRole {
  BotAdmin,
  Editor,
  Agent,
  Analyst,
  Marketer,
  Viewer,
  Owner,
  Supervisor,
  AppManager,
  SuperAdmin,
}

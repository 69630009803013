import { Customer } from "src/app/services";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { Auth, SendOTPRes, SignUpInfo, VerifyData } from "./auth.model";
import { LocalStorageService } from "../../core/local-storage.service";
import { AppConfig } from "src/app.config";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { UserLanguage } from "src/app/shared/enums/user-language.enum";
import { map } from "rxjs/operators";
import { User } from "./user";
import { ActivatedRoute, Router } from "@angular/router";
import { AppCookiesService } from "../cookies/app-cookies.service";
import { EndPoint } from "src/EndPoints/EndPoint";
import { AppConfigurations } from "src/app/shared/models/app-config.model";
import { AppService } from "src/app/shared/services/app.service";
import { BotsManagementService } from "../Management-services/bots-management/bots-management.service";
import { ThirdPartyOAuthType } from "src/app/modules/auth/models/login.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  headers = new HttpHeaders().set("content-type", "application/json");

  baseUrl = AppConfig.BaseUrl + "/";
  httpClientx: HttpClient;
  isOnPrem = AppConfig.isOnPrem;
  logoSrc = "";
  dataSubject = new Subject();
  isAuthenticating = false;
  isPartnerSubject = new Subject<boolean>();
  /** for emit if token time is end */
  sendRefreshTokenSubject: Subject<boolean> = new Subject<boolean>();
  /** for emit if last interact time is end */
  sendLastInteractionSubject: Subject<boolean> = new Subject<boolean>();
  /** for set tokenTime settimeout to clear it*/
  TokenTime: any;
  /** for set interact settimeout to clear it*/
  InteractionTime: any;
  cognitoCode: string | number;
  customerData: Customer;
  verifiedCustomer: boolean;
  notCognitoRoute: boolean;
  isOnCognito = AppConfig.isOnCognito;
  userDomain = AppConfig.X_USER_DOMAIN(window.location.host);

  constructor(
    public jwtHelper: JwtHelperService,
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private _localStorage: LocalStorageService,
    private router: Router,
    private cookieService: AppCookiesService,
    private route: ActivatedRoute,
    private appService: AppService,
    private botManagementService: BotsManagementService
  ) {
    this.appService.getDomainConfiguration().subscribe((res: AppConfigurations) => {
      if (res && res.AppLogo) {
        this.logoSrc = res.AppLogo;
      }
    });
    this.httpClientx = new HttpClient(handler);
    this.route.queryParams.subscribe((params) => {
      if (this.route.snapshot.queryParams["state"]) {
        this.notCognitoRoute = true;
      } else {
        this.notCognitoRoute = false;
      }
      //check lead Code here
      if (params["code"] && !this.notCognitoRoute) {
        this.cognitoCode = params["code"];
        this.loginWithCognito(this.cognitoCode).subscribe((response: any) => {
          if (response) {
            if (response.UserToken) {
              const editedRes = response;
              editedRes.PictureURL = response.PictureURL || this.logoSrc;
              this.customerData = response;
              this.setLocalStorageData(this.customerData);
              this.verifiedCustomer = true;
              this.cookieService.setSignedInCookie();
              this.router.navigate(["/bot/list"]);
            } else {
              this._localStorage.setCurrentCustomerEmail(response.Email);
              this.router.navigate(["/auth/two-factor"]);
            }
          }
        });
      }
    });
  }

  public get isAuthenticated(): boolean {
    const token = this._localStorage.getToken();
    const currentUser = this._localStorage.getCurrentCustomerId();

    if (currentUser && token) {
      try {
        return !this.jwtHelper.isTokenExpired(token);
      } catch (error) {
        return false;
      }
    } else if (
      window.location.href.includes("register") ||
      this.router.url === "/auth/forgot-password" ||
      window.location.href.includes("login") ||
      this.router.url === "/auth/change-password" ||
      window.location.href.includes("reset-password") ||
      this.router.url === "/auth/verify" ||
      this.router.url === "/auth/verify-email" ||
      this.router.url === "/auth/sent" ||
      this.router.url === "/auth/failed" ||
      this.router.url === "/auth/reset-successfully" ||
      this.router.url === "/auth/new-email" ||
      window.location.href.includes("confirm-newEmail") ||
      this.router.url === "/auth/newEmailVerified" ||
      this.router.url === "/auth/two-factor" ||
      this.router.url === "/auth/diff-method" ||
      this.router.url === "/auth/send2FA-email" ||
      this.router.url === "/auth/success-2FA" ||
      this.router.url === "/auth/sign-up" ||
      window.location.href.includes("auth/confirm")
    ) {
      return true;
    }

    return false;
  }

  public socialMediaLogin(code: string, state: string, thirdPartyType: ThirdPartyOAuthType) {
    let currentAuthState = this._localStorage.getAuthState();

    let permissionState = state;

    if (currentAuthState === "permissions") {
      permissionState = "2cd7b1c9d1e743958edf0fc381e22f5d";
    }

    if (thirdPartyType === ThirdPartyOAuthType.Facebook && this._localStorage.getfbLoginCode() === code) {
      return;
    }

    // if (thirdPartyType === ThirdPartyOAuthType.Google && this._localStorage.getGoogleLoginCode() === code) {
    //   return;
    // }

    this.isAuthenticating = true;

    let userLang = navigator.language;
    userLang = userLang.split("-")[0];
    const lang = userLang.toLowerCase() === "ar" ? UserLanguage.Arabic : UserLanguage.English;

    this.httpClientx
      .post(
        this.baseUrl + AppConfig.ThirdPartyLogin,
        {
          code: code,
          state: encodeURIComponent(permissionState),
          lang: lang,
          thirdPartyType,
        },
        { headers: new HttpHeaders().set("x-user-domain", this.userDomain), observe: "response" }
      )
      .subscribe((res: any) => {
        if (!res || !res.body) return;
        let redirectURL = window.location.href;
        if (res.status === 202 && res.body.Domain) {
          window.location.href = `${window.location.origin}/hulul_redirect/${userLang.toLowerCase()}?d=${res.body.Domain}`;
          return;
        }
        if (res.body.status === 202) {
          this._localStorage.setAuthState("permissions");
          window.location.href =
            "https://www.facebook.com/v3.1/dialog/oauth" +
            "?client_id=" +
            AppConfig.AppId +
            "&redirect_uri=" +
            redirectURL +
            "&state=" +
            encodeURIComponent(state) + // passing permission State
            "&scope=public_profile%2Cemail%2Cmanage_pages%2Cpages_show_list%2Cpages_messaging%2Cpages_messaging_subscriptions%2Cread_insights";
          return;
        }
        this.dataSubject.next(res.body);
      }, console.error);
  }

  public saveUserEmail(email) {
    return this.httpClient.get(this.baseUrl + EndPoint.BACKEND_PATHS.UpdateEmail + "/" + email);
  }
  // public GetUserData(encryptedData: string) {
  //   const reheader = new HttpHeaders({
  //     'content-type': 'application/json',
  //     'x-user-auth': encryptedData
  //   });
  //   return this.httpClient.post(AppConfig.BaseUrl + 'Authentication/GetData', null, { headers: reheader });
  // }

  // public SignIn(authData: Auth) {
  //   return this.httpClient.post(this.baseUrl + 'Customer/SignIn', authData, { headers: this.headers });
  // }

  // public SignUp(authData: Auth) {
  //   return this.httpClient.post(AppConfig.BaseUrl + 'Customer/SignUp', authData, { headers: this.headers });
  // }

  public SignOut() {
    return this.httpClient.get(this.baseUrl + EndPoint.BACKEND_PATHS.SignOut);
  }

  renewToken() {
    return this.httpClient.get(this.baseUrl + EndPoint.BACKEND_PATHS.RenewToken);
  }

  renewPermissions(token: any) {
    return this.httpClient.put(this.baseUrl + EndPoint.BACKEND_PATHS.RefreshFbToken, token);
  }

  login(email: string, password: string, ReCaptchaToken: string) {
    return this.httpClientx.post(
      this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.AccountLogin,
      { email: email, password: password, ReCaptchaToken: ReCaptchaToken },
      { withCredentials: true, headers: new HttpHeaders().set("x-user-domain", this.userDomain), observe: "response" }
    );
  }

  registerAdmin(user: SignUpInfo | User): Observable<Customer> {
    return this.httpClientx.post<Customer>(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.SignUp, user, {
      headers: new HttpHeaders().set("x-user-domain", this.userDomain),
    });
  }

  sendOTP(SignUpInfo: SignUpInfo): Observable<SendOTPRes> {
    return this.httpClient.post<SendOTPRes>(this.baseUrl + EndPoint.INTEGRATIONS_PATHS.SendOtp, SignUpInfo);
  }

  verifyOtp(data: VerifyData): Observable<{ Code: string; CustomerId: string; UserToken: string }> {
    return this.httpClient.post<{ Code: string; CustomerId: string; UserToken: string }>(this.baseUrl + EndPoint.INTEGRATIONS_PATHS.VerifyOtp, data);
  }

  phoneExists(phoneNumber: string): Observable<any> {
    const headers = new HttpHeaders({
      "x-user-domain": this.userDomain,
      phone: phoneNumber,
    });
    return this.httpClient.get<any>(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.PhoneExists, { headers });
  }

  emailExists(email: string): Observable<any> {
    const headers = new HttpHeaders({
      "x-user-domain": this.userDomain,
      email: email,
    });
    return this.httpClient.get<any>(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.EmailExists, { headers });
  }

  changePassword(currentPassword: string, newPassword: string) {
    const usertoken = this._localStorage.getToken();
    const setHeaders = new HttpHeaders({
      Authorization: `Bearer ${usertoken}`,
    }).set("x-user-domain", this.userDomain);
    return this.httpClient.post(
      this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.ChangePassword,
      { currentPassword: currentPassword, newPassword: newPassword },
      { headers: setHeaders }
    );
  }

  changeEmail(email: string, password: string) {
    return this.httpClient.post(
      this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.CheckPassword,
      { email: email, password: password },
      { headers: new HttpHeaders().set("x-user-domain", this.userDomain) }
    );
  }

  resetPasswordEmail(email: string) {
    return this.httpClient.post<any>(
      this.baseUrl + EndPoint.BACKEND_PATHS.ResetPasswordEmail,
      { email: email },
      { headers: new HttpHeaders().set("x-user-domain", this.userDomain) }
    );
  }

  resetPasswordLink(ResetCode: string, DeleteResetCode) {
    return this.httpClient.post<any>(
      this.baseUrl + EndPoint.BACKEND_PATHS.IsValidResetLink,
      { ResetCode: ResetCode, DeleteResetCode: DeleteResetCode },
      { headers: new HttpHeaders().set("x-user-domain", this.userDomain) }
    );
  }

  resetPassword(password, ResetCode, email) {
    return this.httpClient.post<any>(
      this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.ResetPassword,
      { password: password, ResetCode: ResetCode, email: email },
      { headers: new HttpHeaders().set("x-user-domain", this.userDomain) }
    );
  }

  resetUserPassword(password: string, email: string, ReCaptchaToken: string, ResetCode: string) {
    const botId = this.botManagementService.getCurrentBotId();
    const headers = new HttpHeaders({
      "content-type": "application/json",
      "x-bot-admin": "" + botId,
      "x-user-domain": this.userDomain,
    });
    return this.httpClient.post<any>(
      this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.ResetUserPassword,
      { password: password, email: email, ReCaptchaToken: ReCaptchaToken, ResetCode },
      { headers: headers }
    );
  }

  refreshAccessToken() {
    const usertoken = this._localStorage.getToken();
    const setHeaders = new HttpHeaders({
      Authorization: `Bearer ${usertoken}`,
    });
    return this.httpClient.post(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.RefreshToken, "", { headers: setHeaders, responseType: "text" });
  }
  /**Login with aws cognito */
  loginWithCognito(code): Observable<any> {
    return this.httpClientx.post(this.baseUrl + EndPoint.AUTENTICATIONS_PATHS.CognitoLogin + code, "", {
      withCredentials: true,
      headers: new HttpHeaders().set("x-user-domain", this.userDomain),
    });
  }

  setLocalStorageData(customer) {
    this._localStorage.setCurrentCustomerId(customer.EncryptedId);
    this._localStorage.setCurrentCustomerIdPlain(customer.Id);
    this._localStorage.setCurrentCustomerEmail(customer.Email);
    this._localStorage.setCurrentUserPicutre(customer.PictureURL);
    this._localStorage.setCurrentUserUsername(customer.FirstName + " " + customer.LastName);
    this._localStorage.setAuthId(customer.AuthId);
    this._localStorage.setToken(customer.UserToken);
    this._localStorage.setLanguage(customer.SelectedLanguage);
    this._localStorage.setCurrentCustomerPartner(customer.PartnerRef);
    this.cookieService.setProfilePic(customer.PictureURL);
    this.isPartnerSubject.next(customer.PartnerRef);
    this.setTokenTime();
  }

  /**
   * when user login we set the login time in localstorage
   * and add settimeout to send refresh token before it expire
   */
  setTokenTime() {
    if (this.TokenTime) {
      clearTimeout(this.TokenTime);
    }
    let time = new Date();
    this._localStorage.setloginTime(time.getTime());
    this.TokenTime = setTimeout(() => {
      this.sendRefreshTokenSubject.next(true);
    }, AppConfig.AcessTokenTime - 300000); // before 5 min of expiration
  }

  /**
   * when user send any request we set the last interact time in localstorage
   * and add settimeout to prevent send refresh token if the user doesn't interact
   */
  setLastInteractionTime() {
    if (this.InteractionTime) {
      clearTimeout(this.InteractionTime);
    }
    let time = new Date();
    this._localStorage.setlastInteractionTime(time.getTime());
    this.InteractionTime = setTimeout(() => {
      this.sendLastInteractionSubject.next(true);
    }, AppConfig.AcessTokenTime);
  }

  /**
   * clear settimeout and remove login time from localStorage
   */
  removeRefreshToken() {
    this._localStorage.removeloginTime();
    clearTimeout(this.TokenTime);
    clearTimeout(this.InteractionTime);
  }

  signOut() {
    this.cookieService.deleteSignedInCookie();
    this.SignOut().subscribe((result) => {
      this._localStorage.clearAll();
      if (this.isOnCognito === true) {
        const cognitoLoginUrl = AppConfig.cognitoLogin;
        window.open(cognitoLoginUrl);
      } else {
        this.router.navigate([AppConfig.LoginPage]);
      }
    });
  }
}

import { Bot } from "../..";
import { UserLanguage } from "src/app/shared/enums/user-language.enum";
import { AgentStatus } from "../../auth/agent.model";

export class Customer {
  FirstName: string;
  LastName: string;
  Email: string;
  PictureURL: string;
  AuthId: string;
  SelectedLanguage: UserLanguage;

  Bots: Bot[];
  PaymentMethodId: number;
  Id: number;
  IsDeleted: boolean;
  EncryptedId: string;
  UserToken: string;
  CustomerAuthId: string;
  PartnerRef: boolean;
  EmailConfirmed: boolean;
  TwoFactorEnabled: boolean;
  AgentStatus: AgentStatus;
  NeedResetPassword: boolean;
  PhoneNumber: string;
}

export interface CustomerInfo {
  Id?: number;
  BrandName: string;
  BusinessField: string;
  BusinessLink: string;
  Location: string;
  Customer?: Customer;
}

export enum UserLanguage {
  Arabic = 1,
  English = 2,
  French = 3,
}

export interface Language {
  code: string;
  type: UserLanguage;
  dir: string;
  name: string;
  newFlow: string;
  newFolder: string;
  isRTL: boolean;
}

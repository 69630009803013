
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';


const routes: Routes = [
  {
    path: 'join',
    loadChildren: './modules/join/join.module#JoinModule'
  },
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: '',
    loadChildren: './modules/layout/layout.module#LayoutModule'
  },
  {
    path: '**',
    redirectTo: 'bot/list'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRouterModule { }


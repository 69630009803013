import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Broadcast } from '../../broadcast/broadcast.model';
import { AppConfig } from './../../../../app.config';
import { HttpClient } from '@angular/common/http';
import { EndPoint } from 'src/EndPoints/EndPoint';

@Injectable({
  providedIn: 'root'
})
export class BroadcastManagementService {

  broadcastSubject: Subject<any> = new Subject();
  broadcastState: Observable<any> = this.broadcastSubject.asObservable();

  private broadcast: Broadcast;

  constructor(private _http: HttpClient) { }

  setCurrentState(broadcast: Broadcast) {

    this.broadcast = broadcast;

    this.broadcastSubject.next({ broadcast: this.broadcast });

  }

  checkPageAssociation(pageId) {
    return this._http.get(`${AppConfig.BaseUrl}/${EndPoint.BACKEND_PATHS.PageAssociationRequest}/${pageId}`);
  }
}

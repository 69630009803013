import { PlaygroundFolder } from '../..';
import { Output, EventEmitter, Injectable } from '@angular/core';
import { PlaygroundFlow } from '../../flow';
import { AutoSavingService } from '../../../core';
import { AppTranslateService } from '../../translation/app-translate.service';

@Injectable({
  providedIn: 'root'
})
export class FoldersManagementService {
  currentFolder: PlaygroundFolder;
  currentFolderId: number;
  currentFolders: PlaygroundFolder[];
  defaultFolder: PlaygroundFolder;

  @Output() currentFoldersUpdate = new EventEmitter();

  constructor(private autoSave: AutoSavingService,
    public appTranslateService: AppTranslateService
  ) { }

  /**
   * sets current folder
   * @param currentFolder :current folder of current flow
   */
  setCurrentFolder(currentFolder: PlaygroundFolder) {
    this.currentFolder = currentFolder;
    this.currentFolderId = currentFolder.Id;
  }

  /**
   * sets current folder Id
   * @param currentFolderId: id of current folder
   */
  setCurrentFolderId(currentFolderId: number) {
    this.currentFolderId = currentFolderId;
  }

  /**
   * sets current folders
   * @param currentFolders :
   */
  setCurrentFolders(currentFolders: PlaygroundFolder[]) {

    this.currentFolders = currentFolders;
    this.defaultFolder = currentFolders.find(folder => folder.IsDefault === true);
    this.setIsCollapsedAndFocus();
    this.currentFoldersUpdate.emit({ updatedFolders: this.currentFolders });
  }

  private setIsCollapsedAndFocus() {
    this.currentFolders.forEach(folder => {
      if (folder.IsDefault === true) {
        folder.IsCollapsed = false;
        folder.Focus = false;
      } else if (folder.IsCollapsed === undefined) {
        const items =JSON.parse(localStorage.getItem('Folders IDS')) || []; 
        const folders = this.currentFolders.filter((folder)=>{
          return items.includes(folder.Id);
        })
        folders.forEach((fol)=>{
          fol.IsCollapsed=true;
        })
        folder.Focus = false;
      }
    });
  }

  /**
   * sets default folder
   * @param deafultFolder :default folder of
   */
  setDefaultFolder(deafultFolder: PlaygroundFolder) {
    this.defaultFolder = deafultFolder;
  }
  /**
   * gets default folder
   */
  getDefaultFolder() {
    return this.defaultFolder;
  }

  /**
   * adds folder to list
   * @param folder :folder that will be added
   */
  addFolder(folder: PlaygroundFolder) {
    this.currentFolders.push(folder);
    this.setCurrentFolders(this.currentFolders);
  }

  /**
   * deleted folder from current folders
   * @param folder
   */
  deleteFolder(folder: PlaygroundFolder) {
    this.currentFolders = this.currentFolders.filter((fold: PlaygroundFolder) => fold.Id !== folder.Id);
    this.setCurrentFolders(this.currentFolders);
  }
  /**
   * adds new flow in folder flows list
   * @param folder: the folder that flow will be added to
   * @param newFlow: the new flow that will be added
   */
  addFlowInFolders(folder: PlaygroundFolder, newFlow: PlaygroundFlow) {
    const folders = this.getCurrentFolders();
    const folderIndex = folders.indexOf(folder);
    folder.Flows.push(newFlow);
    folders[folderIndex] = folder;
    this.setCurrentFolders(folders);
  }

  /**
   * deletes flow from folders list
   * @param deletedFlow : flow that will be deleted from
   */
  deleteFlowFormFolders(deletedFlow: PlaygroundFlow) {
    const folders = this.getCurrentFolders();

    const folderIndex = this.getFolderIndex(deletedFlow.FolderId);

    // let flowIndex=this.getFlowIndex(folderIndex,deletedFlow.Id);

    folders[folderIndex].Flows = folders[folderIndex].Flows.filter(flow => flow.Id !== deletedFlow.Id);
    this.setCurrentFolders(folders);
  }

  private getFolderIndex(neededFolderId: number) {
    const folders = this.getCurrentFolders();

    const retFolder = this.getCurrentFolders()
      .find(folder => folder.Id === neededFolderId);

    const folderIndex = folders.indexOf(retFolder);

    return folderIndex;
  }
  /**
   *
   * @param folderIndex
   * @param flowId
   */
  private getFlowIndex(folderIndex: number, flowId: number) {
    const folders = this.getCurrentFolders();
    const folder = folders[folderIndex];
    const retFlow = folder.Flows.find(f => f.Id === flowId);
    const flowIndex = folder.Flows.indexOf(retFlow);
    return flowIndex;
  }

  /**
   * updates Invalid state of current flow and current folders
   * @param currentFlow
   * @param currentState
   */
  updateInvalidFlow(currentFlow: PlaygroundFlow) {

      const folderIndex = this.getFolderIndex(this.currentFolderId);
      const flowIndex = this.getFlowIndex(folderIndex, currentFlow.Id);
      if (flowIndex > -1) {
        this.currentFolders[folderIndex].Flows[flowIndex].IsValidFlow = currentFlow.IsValidFlow;
        this.setCurrentFolders(this.currentFolders);
      }
  }
  /**
   *
   * @param updatedFlow
   */
  updateFlowInFolders(updatedFlow: PlaygroundFlow) {
    const folders = this.getCurrentFolders();

    const folderIndex = this.getFolderIndex(updatedFlow.FolderId);

    const flowIndex = this.getFlowIndex(folderIndex, updatedFlow.Id);
    folders[folderIndex].Flows[flowIndex].Name = updatedFlow.Name;

    this.setCurrentFolders(folders);
  }
  /**
   * updates folders list with new list
   * @param folders
  */
  updateFolders(folders: PlaygroundFolder[]) {
    if (folders[0] === this.getDefaultFolder()) {
      this.setCurrentFolders(folders);
    } else {
      let currFolders = [];
      currFolders.push(this.getDefaultFolder());
      currFolders = currFolders.concat(folders);
      this.setCurrentFolders(currFolders);
    }
  }

  /**
   * get max number of newFolders created
   */
  checkMaxNewFolder() {
    let folders = this.getCurrentFolders();
    let maxNewFolder = -1;
    let number: number;
    let matchingName: string; 

    if (this.appTranslateService.currentLanguage === 'en') {
      matchingName = 'New Folder';
    } else if (this.appTranslateService.currentLanguage === 'fr') {
      matchingName = 'Nouveau dossier';
    } else if (this.appTranslateService.currentLanguage === 'ar') {
      matchingName = 'حافظة جديدة';
    }

    if (folders == null) { folders = []; }
    folders.forEach(folder => {
      if (folder.Name.startsWith(matchingName)) {
        number = 0;
        const arrName = folder.Name.split(' ');
        if (arrName.length === 3) { number = +arrName[2]; }
        if (number > maxNewFolder && number <= 100) { maxNewFolder = number; }
      }
    });
    return maxNewFolder;
  }

  /**
   * gets current Folder Id
   */
  getCurrentFolderID() {
    return this.currentFolderId;
  }

  /**
   * gets current folder
   */
  getCurrentFolder() {
    return this.currentFolder;
  }

  /**
   * get current folders
   */
  getCurrentFolders() {
    if (!this.currentFolders) {
      // ToDo
    }
    return this.currentFolders;
  }

  /**
   * re-ordering folders of bot
   * @param Folders
   */
  reOrderingBotFolders(Folders: PlaygroundFolder[]) {
    let count = 1;
    Folders.forEach(folder => {
      folder.Order = count++;
    });

    Folders = this.sortFolders(Folders);
    // this.setCurrentFolders(Folders);
  }

  /**
   * sort folders according to order
   * @param folders :folders that will be sorted
   */
  sortFolders(folders: PlaygroundFolder[]) {
    return folders.sort((firstFolder, secondFolder) => {
      const v = firstFolder.Order - secondFolder.Order;
      return v;
    });
  }

}

export enum Comparer {
    None,
    ExactlyIs,
    Is,
    IsNot,
    Contains,
    StartsWith,
    EndsWith,
    DoesNotStartWith,
    ItsDomainIs,
    ItsDomainIsNot,
    WithinRange,
    LessThan,
    GreaterThan,
    IsMatched,
    IsNotMatched,
    NotSet,
    AnyValue,
    MINUTESAGO,
    MINUTESFROMNOW,
    NOTMINUTESAGO,
    NOTMINUTESFROMNOW,
    DOESNOTCONTAINS 

}

export enum DateOptions {
    SpecificDay = 1,
    CurrentDay,
    NextDay,
    NextWeek
}
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app.config';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BotRole } from './bot-role.enum';
import { BotJoinedCustomersModel } from './bot-joined-customers-model';
import { EndPoint } from 'src/EndPoints/EndPoint';
import { TagType, Team } from './bot-team-model';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddonsServices } from 'src/app/modules/layout/bot-settings/shared/models/addons.model';

@Injectable({
  providedIn: 'root'
})
export class BotJoinsService {

  headers = new HttpHeaders().set('content-type', 'application/json');

  baseUrl = AppConfig.BaseUrl + '/';
  botId: number | string;
  constructor(private _http: HttpClient) { }

  private _teams = new BehaviorSubject<any>({})
  private _teams$ = this._teams.asObservable()

  getTeams() {
    return this._teams$
  }
  setTeams(newVal) {
    return this._teams.next(newVal)
  }

  private _hasInboxSeat = new BehaviorSubject<{hasInboxSeat: boolean}>({hasInboxSeat: false})
  private _hasInboxSeat$ = this._hasInboxSeat.asObservable()
  getInboxSeat() {
    return this._hasInboxSeat$
  }
  setInboxSeat(newVal) {
    return this._hasInboxSeat.next({hasInboxSeat: newVal})
  }

  private _checkRole = new BehaviorSubject<any>({currentRole: 0})
  private _checkRole$ = this._checkRole.asObservable()
  getRole() {
    return this._checkRole$
  }
  setRole(newVal) {
    return this._checkRole.next({currentRole: newVal})
  }

  getBotJoins(botId): Observable<BotJoinedCustomersModel[]> {
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'x-bot': '' + botId
    });
    return this._http.get<BotJoinedCustomersModel[]>(this.baseUrl + EndPoint.BACKEND_PATHS.BotAdmins + '/' + botId, { headers: headers });
  }

  addTeam(team: Team) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.Tag, team).pipe(tap(() => this.setTeams({ team })))
  }

  updateTeam(team: Team) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.Tag, team);
  }

  deleteTeam(teamId, botId, type): Observable<any> {
    return this._http.request('delete', this.baseUrl + EndPoint.BACKEND_PATHS.Tag, {
      body: { id: teamId, botId: botId, type: type },
    }).pipe(tap(() => this.setTeams({ teamId })))
  }

  unassignTeam(TagId, customerId): Observable<any> {
    return this._http.request(
      "delete",
      this.baseUrl + EndPoint.BACKEND_PATHS.UnassignTeam,
      {
        body: { TagId, customerId },
      }
    );
  }
  assignTeam(TagId, CustomerId) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.AssignTeam, {
      TagId,
      CustomerId,
    });
  }

  private getBotByTagType(botId, tagType: TagType, page?, limit?) {
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'x-bot': '' + botId
    });

    let params = new HttpParams().set('tagType', tagType.toString());

    if (page !== undefined) {
      params = params.set('page', page.toString());
    }

    if (limit !== undefined) {
      params = params.set('limit', limit.toString());
    }

    const url = `${this.baseUrl}${EndPoint.BACKEND_PATHS.BotTags}/${botId}`;
    return this._http.get(url, { params, headers });
  }

  getBotTags(botId, page?, limit?) {
    return this.getBotByTagType(botId, TagType.Chat, page, limit);
  }

  getBotTeams(botId) {
    return this.getBotByTagType(botId, TagType.Team);
  }

  getSearchTag(botId, searchText: string){
    const url = `${this.baseUrl}${EndPoint.BACKEND_PATHS.TagSearch}/${botId}?keyword=${searchText}`;    
    return this._http.get(url);
  }

  addTag(tag: Team) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.Tag, tag)
  }

  updateTag(tag: Team) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.Tag, tag);
  }

  deleteTag(teamId, botId, type): Observable<any> {
    return this._http.request('delete', this.baseUrl + EndPoint.BACKEND_PATHS.Tag, {
      body: { id: teamId, botId: botId, type: type },
    })
  }

  // getBotJoin(pageId) {
  //   return this._http.post(this.baseUrl + 'sendJoinRequest', pageId);
  // }

  leaveBot(botId: number) {
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'x-bot': '' + botId
    });
    return this._http.delete(this.baseUrl + EndPoint.BACKEND_PATHS.LeavBot + '/' + botId, { headers: headers });
  }

  removeAdmin(joinId: number) {
    return this._http.delete(this.baseUrl + EndPoint.BACKEND_PATHS.RemoveAdmin + '/' + joinId, { headers: this.headers });
  }

  getBotInvitation(botId: number, role: BotRole) {
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.CreateInvitation + '/' + botId + '/' + role, { headers: this.headers });
  }

  editBotRole(botRole: BotJoinedCustomersModel) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.BotJoinsRole, botRole);
  }

  assignInboxSeat(botRole: BotJoinedCustomersModel) {
    return this._http.put(this.baseUrl + EndPoint.BACKEND_PATHS.AssignInboxSeat, botRole);
  }

  unassignInboxSeat(JoinId) {
    return this._http.delete(this.baseUrl + EndPoint.BACKEND_PATHS.UnassignInboxSeat + '/' + JoinId);
  }

  inboxWorkingDays(InboxWorkingDays: InboxWorkingDaysModel) {
    return this._http.post(this.baseUrl + EndPoint.BACKEND_PATHS.InboxWorkingDays, InboxWorkingDays);
  }

  getInboxTags(userId){
    return this._http.get(this.baseUrl + EndPoint.BACKEND_ANALYTICS_PATHS.GetInboxTags+ '/' + userId);
  }

  getAgentsByTag(botId, tagId, withTeamLess: boolean){
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.AgentsByTag+ '/' + botId + '/' + tagId,
    {params: {
      filterWithTeamlessAgents: withTeamLess + ""
    }});
  }

  addInboxTags(TagId,UserId,AgentId,CreationDate){
    return this._http.post(this.baseUrl + EndPoint.BACKEND_ANALYTICS_PATHS.InsertInboxTags,{
      TagId,
      UserId,
      AgentId,
      CreationDate
    });
  }

  deleteInboxTags(UserId,TagId){
    return this._http.delete(this.baseUrl + EndPoint.BACKEND_ANALYTICS_PATHS.GetInboxTags + '/' + UserId + '/' + 'Tag' + '/'+ TagId);
  }
  deleteInboxTag(TagId){
    return this._http.delete(this.baseUrl + EndPoint.BACKEND_ANALYTICS_PATHS.DeleteInboxTag + '/'  + TagId);
  }

  getBotCapacity(botId){
    return this._http.get(this.baseUrl + EndPoint.BACKEND_PATHS.BotCapacity + '/' + botId);
  }

  updateBotCapacity(botId, capacity = "") {
    const url = `${this.baseUrl + EndPoint.BACKEND_PATHS.BotCapacity}/${botId}`;
    return this._http.put(url, {}, { params: { capacity } });
  }

  updateAgentCapacity(botId: number, adminId: number, capacity: string) {
    const url = `${this.baseUrl + EndPoint.BACKEND_PATHS.AgentCapacity}/${botId}/${adminId}`;
    return this._http.put(url, {}, { params: { capacity } });
  }

  /** Update the status toggle */
  updateAddons(botId: number, service: AddonsServices) {
    const url = `${this.baseUrl}${EndPoint.BACKEND_PATHS.Addons}/UpdateAddonService/${botId}/${service}`;
    return this._http.put(url, service);
  }

  /** Get addons status and details for connected channels only */
  getConnectedAddons(botId: number) {
    const url = `${this.baseUrl}${EndPoint.BACKEND_PATHS.Addons}/addonsStatus/channel/${botId}`;
    return this._http.get(url);
  }
}

export interface InboxWorkingDaysModel {
  Day: WorkingDaysModel,
  FromHour: number,
  ToHour: number
}

export enum WorkingDaysModel {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

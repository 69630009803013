import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, combineLatest, of } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";

import { AppCookiesService } from "src/app/services/cookies/app-cookies.service";
import { AppConfig } from "src/app.config";
import { PaymentStoreService } from "src/app/modules/payment/services/payment-store.service";
import { UnsupportedFeatures } from "src/app/shared/enums/modules.enum";
import { map, tap } from "rxjs/operators";
import { BotSubscription } from "src/app/services/bot-settings/pricing/bot-subscription.model";
import { BotSubscriptionService } from "src/app/modules/payment/services/bot-subscription.service";
import { BotJoinsService } from "src/app/services/bot-settings/bot-joins/bot-joins.service";
import { LocalStorageService } from "../local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  isOnPrem = AppConfig.isOnPrem;
  UnsupportedFeatures = UnsupportedFeatures;
  customerId = 0;
  constructor(
    public auth: AuthService,
    public router: Router,
    public cookieService: AppCookiesService,
    private paymentStoreService: PaymentStoreService,
    private localStorage: LocalStorageService,
    private botSubscriptionService: BotSubscriptionService,
    private botJoinsService: BotJoinsService
  ) {
    this.customerId = +this.localStorage.getCurrentCustomerIdPlain();
  }
  isOnCognito = AppConfig.isOnCognito;

  canActivate(
    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!this.auth.isAuthenticated) {
      this.cookieService.deleteSignedInCookie();
      this.auth.SignOut();

      if (this.isOnCognito === true) {
        const cognitoLoginUrl = AppConfig.cognitoLogin;
        window.open(cognitoLoginUrl);
      } else {
        this.router.navigate([AppConfig.LoginPage]);
      }

      return of(false);
    }

    if (state.url.includes("identity-manager")) {
      if (next.children[0] && next.children[0].children[0] && next.children[0].children[0].params) {
        return this.checkIDMSupported(+next.children[0].children[0].params["id"]);
      }
    }

    return of(true);
  }

  checkIDMSupported(botId: number) {
    return combineLatest(this.botJoinsService.getBotJoins(botId), this.botSubscriptionService.getBotSubscription(botId)).pipe(
      map(([admins, sub]) => {
        const currAdmin = [...admins].find((admin) => {
          return admin.CustomerId === this.customerId;
        });
        const unsupportedFeatures = this.paymentStoreService.combineUnsupportedLists(sub.BillingPlan, currAdmin);
        const supported = this.paymentStoreService.isSupported(UnsupportedFeatures.Identitymanager, unsupportedFeatures);
        if (!supported) {
          this.router.navigate(["bot", "list"]);
        }

        return supported;
      })
    );
  }
}

import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app.config";
import { BehaviorSubject } from "rxjs";
import { EndPoint } from "src/EndPoints/EndPoint";
import { AppConfigurations } from "../models/app-config.model";
@Injectable({
  providedIn: "root",
})
export class AppService {
  httpClientx: HttpClient;
  private backendAppUrl = AppConfig.BaseUrl + "/" + EndPoint.BACKEND_PATHS.Domain;

  private domainConfig = new BehaviorSubject<AppConfigurations>(null);

  getDomainConfiguration() {
    return this.domainConfig.asObservable();
  }

  setDomainConfiguration(value: AppConfigurations) {
    this.domainConfig.next(value);
  }

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClientx = new HttpClient(handler);
  }

  getConfiguration() {
    let headers = {};
    headers["x-user-domain"] = AppConfig.X_USER_DOMAIN(window.location.host);
    return this.httpClientx.get(this.backendAppUrl, { headers, withCredentials: true });
  }

  widebotConfigs: AppConfigurations = {
    AppLogo: "https://widebot-webchat.s3.amazonaws.com/attachments/logo.png",
    AppLogoEN: "https://widebot-webchat.s3.amazonaws.com/attachments/logoEN.png",
    AppLogoAR: "https://widebot-webchat.s3.amazonaws.com/attachments/LogoAR.png",
    PlatformLogo: "./assets/images/logo.png",
    AppName: "Widebot Platform",
    AppSecondaryColor: "#FC5000",
    AnnualPlansDiscount: 0,
    HasContactUs: true,
    AppLogin: "WIDEBOT.LOGIN",
    AppRegister: "WIDEBOT.REGISTER",
    HelpCenterUrl: "https://support.widebot.net/hc/en-us",
    FeedbackUrl: "https://feedback.widebot.net/feedback",
    CategoriesUrl: "https://support.widebot.net/hc/en-us/articles/16127710074514-Setting-Up-Human-Handover",
    AiTrainUrl: "https://support.widebot.net/hc/en-us/articles/8402030890130-A-I-Skills-Beta",
    LearnMoreAboutScenariosUrl: "https://support.widebot.net/hc/en-us/articles/15083141714706-Train",
    WebchatUrl: "https://support.widebot.net/hc/en-us/articles/15081961569426-Webchat",
    FacebookUrl: "https://support.widebot.net/hc/en-us/articles/8401892592530-Facebook-Messenger",
    WhatsappUrl: "https://support.widebot.net/hc/en-us/articles/8401786172818-WhatsApp",
    InstagramUrl: "https://support.widebot.net/hc/en-us/articles/8401842591250-Instagram",
    TwitterUrl: "https://support.widebot.net/hc/en-us/articles/8401932697874-Twitter",
    GuidelineUrl: "https://support.widebot.net/hc/en-us/articles/15084697041426--Introduction-to-Integrations",
    JsonApiUrl: "https://support.widebot.net/hc/en-us/articles/15083935641234-Setting-up-JSON-API-Advanced",
    LearnMoreAboutFbAutoReplyScenarioUrl: "https://support.widebot.net/hc/en-us/articles/15084255212818-Acquiring-customers-through-comments",
    LearnMoreAboutIgAutoReplyScenarioUrl: "https://support.widebot.net/hc/en-us/articles/15084255212818-Acquiring-customers-through-comments",
    FawrySetupGuideUrl: "https://support.widebot.net/hc/en-us/articles/16716064653330-Fawry",
    NewPlans: false,
    LoginWithFacebook: true,
    HidePlans: false,
    HideSignUpButton: true,
    CanCreateBots: false,
    HideIntercome: false,
    HideProfessionalService: false,
    HideFeedback: false,
    HidePricingProfessionalService: false,
    HideFAQs: false,
    WidebotLogin: true,
    SmsData: {
      ContactSupportUrl: "https://support.widebot.net/hc/en-us/requests/new",
      LearnMoreAboutSMSUrl: "https://support.widebot.net/hc/en-us/articles/17648736404114-SMS",
      LearnMoreAboutContentSmsUrl: "https://support.widebot.net/hc/en-us/articles/17800447541650-Sending-SMS-Broadcasts",
    },
    KnowMoreAboutHandoverUrl: "https://support.widebot.net/hc/en-us/articles/16127710074514-Setting-Up-Human-Handover",
    LeanMoreAboutAttributesUrl: "https://support.widebot.net/hc/en-us/articles/15084100860178-User-Personalization-Attributes",
    ZidSetupGuideUrl: "https://support.hulul.net/hc/en-us/articles/18323073511954-Zid",
    TalkToExpertLink: "https://widebot.net/professional-services/",
    LearnAboutWaAddonsPricingUrl: "https://bots-resources.s3.amazonaws.com/PayAsYouGo/Whatsapp/WA_TemplatePricing_USD.csv",
    ShopifySetupGuideUrl: "https://support.hulul.net/hc/en-us/articles/18323111494034-Shopify",
    EmailChannelContactSupportUrl: "https://support.widebot.net/hc/en-us/requests/new",
    EmailChannelGuideUrl: "https://support.widebot.net/hc/en-us/articles/20205601873042-Guide-to-Setting-Up-Your-Domain-and-Emails",
    EmailBroadcastTemplateUrl: "https://support.widebot.net/hc/en-us/articles/20205629372562-Tools-for-Creating-HTML-Templates",
    AppPlatformNameInPDF: "Widebot",
    CallChannelGuideUrl: "",
    CallChannelContactSupportUrl: "",
    PayTabsSetupGuideUrl: "",
    LearnAboutWaBusinessInitPricingUrl: "https://bots-resources.s3.amazonaws.com/PayAsYouGo/Whatsapp/BI/Other_BI_Pricing.csv",
    HideUpgradePlan: true,
  };
}

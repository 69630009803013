export const environment = {
  production: true,
};

export class EnvironmentConfigsClass {
  public static integrationUrl = "https://platform.widebot.sa/api";

  public static BaseUrl = "https://platform.widebot.sa/gateway";

  public static BaseHref = "";

  public static AppId = "396247243279537"; // "664854958903083";

  public static IntercomAppId = "";

  public static localizationFiles = "./assets/i18n/";

  //Cognito sign In URL
  public static cognitoLogin = "";

  public static enableLoginWithFB = false;

  // loginWithFaceBook
  public static signInUrl = null;

  public static signInHululUrl = null;

  // signInWithGoogleUrl
  public static signInWithGoogleUrl = null;

  public static signalrURL = EnvironmentConfigsClass.BaseUrl + "/inbox/chathub";

  public static segmentioId = "";

  public static webchatFiles = "https://platform.widebot.sa/webchat";

  public static isOnPrem = true;

  public static hasFbIntegrity = false;

  public static isOnCognito = false;

  // POSRocket url
  public static posrocketUrl = "";

  // POSRocket templates ids
  public static posrocketTemplate = 0;

  public static AcessTokenTime = 1800000; // token time 30 min

  public static invitationPath = "/join/confirm/";

  // Foodics url
  public static foodicsUrl = "";
  public static foodicsClientId = "994829c1-5ac3-4a57-b8ef-e58978a5c1c6";
  public static foodicsRedirectURL = `https://console-sandbox.foodics.com/authorize?client_id=${EnvironmentConfigsClass.foodicsClientId}&state=`;
  //  Foodics templates ids
  public static foodicsTemplate = 0;

  // configration toggle
  public static hideRememberMe2FA = false;
  public static hideSendEmail2FA = false;
  public static hideRecaptcha = true;
  // flow configs
  public static MaxNumberOfFlows = 2000;

  // fawrypay app templeteId
  public static fawrypayTemplateId = 28;

  // salla app templateIds
  public static SallaTemplateIds = {
    productListing: 40,
    orderTracking: 42,
  };

  // shopify app templateIds
  public static ShopifyTemplateIds = {
    orderTracking: 44,
    productListing: 43,
  };

  // paytabs app templeteId
  public static PaytabsTemplateId = 35;
}

import { NgModule, InjectionToken } from '@angular/core';
import { UploadManagerUrls } from '../../shared';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  baseUrl: string;
  loginUrl: string;
  defaultLanguage: string;
  uploadFolderURL: string;
  uploadManagerUrls: UploadManagerUrls;
  maxImageSize: number;
  landingPageURL: string;
  landingComponentPath: string;
  localStorage: LocalStorage;
  //IntercomAppId:string;

  //defaultDropzoneConfig: DropzoneConfigInterface;
}
export class LocalStorage {
  authData: string;
}

/* export const Config: AppConfig = {

 //IntercomAppId: "xdw4dknr"; // "ft5fz2qz",//sfvxvepm

  //baseUrl: 'https://widebotplatformapi-development.azurewebsites.net/api/',

  baseUrl:'https://wb-platform-api.azurewebsites.net/api/',

  //baseUrl: 'http://localhost:56835/api/',
  loginUrl: 'https://mail.yahoo.com',
  defaultLanguage: 'en',
  uploadFolderURL: 'http://localhost/widebotfiles',
  uploadManagerUrls: {
    post: 'https://httpbin.org/post',
    get: '',
    put: '',
    delete: ''
  },
  maxImageSize: 50,
  landingComponentPath: "bot/list",
  landingPageURL: "widebot.net",
  localStorage: {
    authData: "authData"
  }
  // defaultDropzoneConfig: {

  // }
}; */

@NgModule({
 /*  providers: [{
    provide: APP_CONFIG,
    useValue: Config
  }] */
})
export class ConfigModule { }

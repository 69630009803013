import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app.config";
import { BotSubscription, PlanUsageParams } from "../../../services/bot-settings/pricing/bot-subscription.model";
import { EndPoint } from "src/EndPoints/EndPoint";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BotSubscriptionService {
  baseUrl = AppConfig.BaseUrl + "/";

  // shopify user subject
  isShopifyUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem("isShopifyUser") ? true : false);

  constructor(private http: HttpClient) {}

  getBotSubscription(botId: number): Observable<BotSubscription> {
    if (!botId) return;
    const headers = new HttpHeaders({
      "content-type": "application/json",
      "x-bot": "" + botId,
    });
    return this.http.get<BotSubscription>(this.baseUrl + EndPoint.BACKEND_PATHS.BotSubscription + "/" + botId, { headers: headers });
  }

  unSubscribeBot(botId: number) {
    return this.http.delete(this.baseUrl + EndPoint.BACKEND_PATHS.BotSubscription + "/" + botId);
  }

  editBotSubscription(botSubscription: BotSubscription) {
    const headers = new HttpHeaders({
      "content-type": "application/json",
      "x-bot": "" + botSubscription.BotId,
    });
    return this.http.put(this.baseUrl + EndPoint.BACKEND_PATHS.Plan, botSubscription, { headers: headers });
  }

  updateBotSubscription(botSubscription: BotSubscription) {
    const headers = new HttpHeaders({
      "content-type": "application/json",
      "x-bot": "" + botSubscription.BotId,
    });
    return this.http.put(this.baseUrl + EndPoint.BACKEND_PATHS.BotSubscription + "/Update", botSubscription, { headers: headers });
  }

  undoUnSubscripeBot(botSubscription: BotSubscription) {
    return this.http.put(this.baseUrl + EndPoint.BACKEND_PATHS.UndoCancellation, botSubscription);
  }

  redeemCoupon(botId: number, couponCode: string) {
    return this.http.post(this.baseUrl + EndPoint.BACKEND_PATHS.Redeem, { BotId: botId, Code: couponCode });
  }

  hasDiscount(botId: number) {
    return this.http.get(this.baseUrl + EndPoint.BACKEND_PATHS.HasDiscount + "/" + botId);
  }

  DowngradeEvaluate(botId, toPlanId) {
    return this.http.get(this.baseUrl + EndPoint.BACKEND_PATHS.DowngradeEval + "/" + botId + "/" + toPlanId);
  }

  PlanUsage(botId: number, params?: PlanUsageParams) {
    let headers = new HttpHeaders({
      "x-bot": "" + botId,
      "x-bot-admin": "" + botId,
    });
    if (params) {
      const [start, end] = this.getDateOnly(params.startDate, params.endDate);
      params.startDate = start ? start : "";
      params.endDate = end ? end : "";
    }
    return this.http.get(`${this.baseUrl}${EndPoint.BACKEND_PATHS.PlanUsage}/${botId}`, { headers: headers });
  }

  cancelCoupon(couponCode: string) {
    return this.http.put(`${this.baseUrl}${EndPoint.BACKEND_PATHS.CancelCoupon}/${couponCode}`, "");
  }

  getDateOnly(startDate: Date, endDate: Date) {
    if (!startDate || !endDate) return [];

    let sd = new Date(startDate.setHours(0, 0, 0, 0));
    let ed = new Date(endDate.setHours(23, 59, 59, 0));
    return [this.localTimeToUTC(sd), this.localTimeToUTC(ed)];
  }

  localTimeToUTC(date: Date) {
    const localTime = new Date(date);

    const utcTime = new Date(localTime.getTime() - localTime.getTimezoneOffset() * 60000);
    return utcTime.toISOString().split(".")[0];
  }

  setIsShopifyUserSubject(value: boolean) {
    this.isShopifyUserSubject.next(value);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app.config';
import { Observable } from 'rxjs';
import { Bot } from 'src/app/services';
import { EndPoint } from 'src/EndPoints/EndPoint';
import { ChannelType } from '../enums/channel-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  baseURL = AppConfig.BaseUrl+ '/';

  constructor(private _http: HttpClient) {}

  getBotUserAnalytics(
    payload: BotUserAnalyticsRequestPayload
  ): Observable<BotUserAnalyticsResponsePayload[]> {
    return this._http.post<BotUserAnalyticsResponsePayload[]>(
      this.baseURL + EndPoint.BACKEND_ANALYTICS_PATHS.GetBotUsersAnalytics,
      payload
    );
  }

  getBlockedConversations(
    payload: BlockedConversationsRequestPayload
  ): Observable<BotUserAnalyticsResponsePayload[]> {
    return this._http.post<BotUserAnalyticsResponsePayload[]>(
      this.baseURL + EndPoint.BACKEND_ANALYTICS_PATHS.GetBlockedConversations,
      payload
    );
  }

  toggleIntegrationConnect(payload: AnanlyticsIntegrationConnectPayload) {
    const botAnalyticsURL = AppConfig.BaseUrl + EndPoint.BACKEND_PATHS.BotAnalytics;
    return this._http.put<Bot>(botAnalyticsURL, payload);
  }

  getBotChannels(botId: number): Observable<ChannelType[]>{
    const reqURL = `${AppConfig.BaseUrl}/${EndPoint.BACKEND_ANALYTICS_PATHS.Channels}/${botId}`;
   return this._http.get<ChannelType[]>(reqURL);
  }

  getBasicAnalytics(botId: number, channelType: number): Observable<BasicAnalyticsResponsePayload> {
    return this._http.get<BasicAnalyticsResponsePayload>(
      this.baseURL + EndPoint.BACKEND_ANALYTICS_PATHS.BasicAnaltics, {
        params: {
          botId: botId.toString(),
          channelType: channelType.toString()
        }
      }
    );
  }
}

export interface BotUserAnalyticsRequestPayload {
  botId: number;
  startingDate: string;
  endingDate: string;
  pageid: string;
  timezoneOffset: number;
}

export interface BlockedConversationsRequestPayload {
  pageAccessToken: string;
  from: string;
  to: string;
}

export interface BotUserAnalyticsResponsePayload {
  UsersNumber: number;
  Date: string;
}

export interface AnanlyticsIntegrationView {
  name: string;
  descriptionTranslateKey: string;
  imgSrc: string;
  isConnected: boolean;
}
export interface AnanlyticsIntegrationConnectPayload {
  ChatbaseApiKey: string;
  Id: number; // Bot Id
}

export interface BasicAnalyticsResponsePayload {
  IncomeMessages: number;
  OutcomeMessages: number;
  NumberOfUsers: number;
}

export enum AnalyticsChannelType {
  Facebook,
  WebChat,
  WhatsApp,
  All = 6
}

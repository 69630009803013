export enum MessageType {
  Text = 0, // content
  QuickReply = 1,
  Gallery = 2, // content
  List = 3, // content
  MediaAttachment = 4, // content
  LocationCard = 5, // content
  Typing = 6,
  UserInput = 7,
  GoToFlow = 8,
  JsonApi = 9,
  SetAttribute = 10,
  HumanHandOver = 11,
  WhatsappList = 12,
  WhatsAppButtonReply = 13,

  ImageAttachment, // content
  VideoAttachment,
  FileAttachment,
  AudioAttachment,
}

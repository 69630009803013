import { Injectable } from '@angular/core';

@Injectable()
export class AppConfigs {

  private configs = {
    AppLogo: "https://widebot-webchat.s3.amazonaws.com/attachments/logo.png",
    AppName: "Widebot Platform",
  };
  private currOrigin = window.location.origin;
  private apiUrl = `${this.currOrigin}/backend/Domain`;
  private isQCEnv = this.currOrigin.includes("qc");
  private isDEVEnv = this.currOrigin.includes("dev");
  private isLocalEnv = this.currOrigin.includes("localhost");

  constructor() {
    if (this.isDEVEnv) {
      this.apiUrl = "https://gateway-dev.widebot.net/backend/Domain";
    } else if (this.isLocalEnv || this.isQCEnv) {
      this.apiUrl = "https://gateway-qc.widebot.net/backend/Domain";
    } else {
      this.apiUrl = "https://gateway.widebot.net/backend/Domain";
    }
  }

  async checkDomain(bootstrap: () => void) {
    try {
      const res: Response = await fetch(this.apiUrl, {
        method: "GET",
        headers: { "content-type": "application/json; charset=UTF-8", "X-User-Domain": window.location.host },
      });
      if (res.ok) {
        const data = await res.json();
        const metaData = JSON.parse(data.Metadata);
        document.getElementById("htmlTitle").innerText = metaData.AppName;
        var link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
        link.href = metaData.AppLogo;
      } else {
        document.getElementById("htmlTitle").innerText = this.configs.AppName;
        var link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
        link.href = this.configs.AppLogo;
      }
      bootstrap();
    } catch (err) {
      console.error("error: ", err);
      document.getElementById("htmlTitle").innerText = this.configs.AppName;
      var link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      link.href = this.configs.AppLogo;
      bootstrap();
    }
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app.config';
import { UserAttribute } from './user-attribute.model';
import { EndPoint } from 'src/EndPoints/EndPoint';
/* import { BaseHttpService } from '../base-http-service.service'; */

@Injectable({
  providedIn: 'root'
})
export class UserAttributeService {//extends BaseHttpService

  url: string = AppConfig.BaseUrl + '/';

  constructor(private _http: HttpClient) {
   }
   getUserAttributes(Id: number) {
    return this._http.get(this.url + EndPoint.BACKEND_PATHS.UserAttribute + '/' + Id);
   }

   getBotUserAttributes(botId: number) {
    return this._http.get(this.url + EndPoint.BACKEND_PATHS.BotAttributes + '/' + botId);
   }

   editUserAttribute(userAttrModel: UserAttribute) {
    return this._http.put(this.url + EndPoint.BACKEND_PATHS.UserAttribute , userAttrModel);
   }

   addUserAttribute(userAttrModel: UserAttribute) {
    return this._http.post(this.url + EndPoint.BACKEND_PATHS.UserAttribute , userAttrModel);
   }

  //  getUserFlowAttribute(FlowId: number) {
  //   return this._http.get(this.url + '/flowAttributes/' + FlowId);
  //  }

   deleteUserAttributes(Id: number) {
    return this._http.delete(this.url + EndPoint.BACKEND_PATHS.UserAttribute + '/' + Id);
   }
  //   deleteUserFlowAttribute(FlowId: number) {
  //     return this._http.get(this.url + '/flowAttributes/' + FlowId);
  //  }
}
